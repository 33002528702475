import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import Logger from "../common/Logger";
import Editable from "../components/control/Editable";
import { ItemTypeIcon, DocumentLinkParent } from "../components/control/ItemTypeIcon";
import ToolbarAction from "../components/control/ToolbarAction";
import ModelJson from "../components/model/ModelJson";
import ModelTable from "../components/model/ModelTable";
import { useModelContext } from "../context/ModelContext";
import { ItemProps } from "../types/Item";
import { useViewContext } from "../context/ViewContext";

const logger = new Logger("view.TableView");

function TableView(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const [activeTab] = useState(1);
  const { key } = useParams();
  const rootKey = key || "";
  const rootItem = model.getItem(rootKey);

  logger.debug("Rendering: rootKey=%s, activeTab=%d:", rootKey, activeTab);
            
  return (
    <Card className="jemstone-view shadow">
      <CardHeader className="">
        <div className="text-nowrap d-flex align-middle">
          <div className="h1 flex-fill">
            { rootItem
              ? <>
                  <SelectButton />
                  <ItemTypeIcon item={rootItem} />
                  <Editable item={rootItem} property={ItemProps.Name} className="d-inline ml-1 mr-1" />
                  <DocumentLinkParent item={rootItem} />
                </>
              : <>
                  <SelectButton />
                  <i className="fal fa-table btn-image mr-1" />
                  <span>Table View</span>
                </>
            }
          </div>
          <ToolbarAction rootKey={rootKey} showExpandAll={true} showRefresh={true} />
        </div>
      </CardHeader>
      <CardBody className="table-view pt-0">
        { activeTab === 1 &&
          <ModelTable rootKey={rootKey} />
        }
        { activeTab === 2 &&
          <ModelJson rootKey={rootKey} />
        }
      </CardBody>
    </Card>
  );

  function SelectButton(props:any) {
    const className = viewState.showSelectCheckbox ? "far fa-check-circle" : "far fa-check-circle";

    return (
      <button className="btn-image mr-1" onClick={toggleSelectCheckbox}>
        <i className={className} />
      </button>
    );

    function toggleSelectCheckbox() {
      viewState.showSelectCheckbox = !viewState.showSelectCheckbox;
      viewState.clearSelected();
      viewState.onEvent({name:"toggleSelectCheckbox", value:viewState.showSelectCheckbox});
    }
  }
}

export default TableView;
