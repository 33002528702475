import React from 'react';
import Logger from "../../common/Logger";
import { isDark } from '../../common/utils';
import { useModelContext } from '../../context/ModelContext';
import { useViewContext } from '../../context/ViewContext';
import { Item } from '../../types/Item';
import { DocumentLink } from '../../views/ViewLinks';
import ContextMenuBase from './ContextMenuBase';
import ItemSearchBox from './ItemSearchBox';

const logger = new Logger("control.ItemPickerTree");

function ItemPickerTree(props:any) {
  const { selected, onChange, readonly, searchKeys } = props;
  const caret = props.caret || "fas fa-caret-down pt-1";
  const format = props.format || formatItem;
  let className = props.className || "";

  logger.trace("Rendering: selected", selected);

  /**
   * This is a bit messy, but if the item to be display has a 'color' property, then use this for
   * the background color and change the text color for readability if necessary
   */
  let styleBackground = {};
  if (selected && selected.color) {
    styleBackground = { backgroundColor: selected.color }
    className += (isDark(selected.color) ? " light" : "");
  }

  return (
    <div className={"dropdown-base " + className} style={styleBackground}>
      <DocumentLink item={selected} />
      { !readonly &&
        <ContextMenuBase caret={caret} placement="bottom-end" logger={logger.name}>
          <TreeView />
        </ContextMenuBase>
      }
    </div>
  )

  function onSelectItem(item:any) {
    logger.debug("onSelectItem: itemKey=%s", item?.key);
    if (onChange !== undefined) {
      onChange(item);
    }
  }

  function formatItem(item:Item | undefined) {
    return (item === undefined) ? "" : item.name;
  }

  function TreeView(props:any) {
    const model = useModelContext().model;
    const viewState = useViewContext();

    return (
      <div className="treeview">
        <div onClick={(e) => e.stopPropagation()}>
          <ItemSearchBox searchKeys={searchKeys} />
        </div>
        { model.childrenSorted("").map(item =>
          <TreeItem itemKey={item.key} key={item.key} />
        )}
      </div>
    );

    function TreeItem(props:any) {
      const item = model.getItem(props.itemKey);
      const level = props.level || 0;

      if (item === undefined || !viewState.isVisible(item.key)) {
        return <></>;
      }
    
      const iconWidth = 1;
      const styleIndent = { paddingLeft: iconWidth*level + "rem" }
      const styleIconWidth = { width: iconWidth + "rem" }
                                      
      const hasViewableChildren = viewState.hasViewableChildren(model, item.key);
      const expanded = viewState.isOpenTree(item.key);
      const itemType = model.getItemType(item) || item;

      const expandIcon = !hasViewableChildren ? "far fa-angle-right text-muted" :
                          expanded ? "far fa-angle-down" : "far fa-angle-right";
      
      logger.trace("TreeItem rendering: itemKey=%s, expanded=%s", props.itemKey, expanded);

      return (
        <>
          <div style={styleIndent}>
            <div className="text-nowrap">
              <button className="btn-image pr-0" onClick={onClick}>
                { level > 0
                  ? <i className={expandIcon} style={styleIconWidth}/>
                  : <i className={itemType.typeIcon} style={styleIconWidth} />
                }
              </button>
              <button className="btn-image item d-inline" onClick={(e) => onSelectItem(item)}>
                { format(item) }
              </button>
            </div>
          </div>
          <div className="">
            { expanded && hasViewableChildren &&
              model.childrenSorted(item.key).map(child =>
                <TreeItem key={child.key} itemKey={child.key} level={level+1} />
            )}
          </div>
        </>
      )

      function onClick(e:any) {
        e.stopPropagation();
        
        const itemState = viewState.toggleOpenTree(item.key);
        if (itemState.openTree) {
          viewState.openTo(model, item.key);
        }
        viewState.onEvent({name:"toggleOpenTree", key:item.key, value:itemState.openTree});
      }
    }
  }
}

export default ItemPickerTree;
