import matchSorter from 'match-sorter';
import React, { useState } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import { useViewContext } from '../../context/ViewContext';
import { Item, ItemProps } from "../../types/Item";

const logger = new Logger("control.ItemSearchBox");

function ItemSearchBox(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const searchKeys = props.searchKeys;
  const [allItems, setAllItems] = useState<Item[]>([]);

  const styleInput = { height:"2rem" }

  let timeoutId:any = null;
  let timeoutPeriod = 300;

  return (
    <InputGroup className="input-group-alternative search">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="far fa-search" />
        </InputGroupText>
      </InputGroupAddon>
      <Input placeholder="Search" type="text" 
             onFocus={onFocus} onBlur={onBlur} onChange={onChange}
             style={styleInput} />
    </InputGroup>
  );
  
  function onFocus(e:any) {
    setAllItems(viewState.getViewableItems(model));
    logger.debug("onFocus: Starting search of %d items...", allItems.length);
  }

  function onBlur(e:any) {
    logger.debug("onBlur: Search is finished");
    setAllItems([]);
  }

  function onChange(e:any) {
    const value = e.target.value;

    if (value === "") {
      viewState.clearSearchHits();
    } else {
      const start = Date.now();
      const results:Item[] = matchSorter(allItems, value, { 
        keys: searchKeys || [
          { key: ItemProps.key,         threshold: matchSorter.rankings.STARTS_WITH },
          { key: ItemProps.Name,        threshold: matchSorter.rankings.WORD_STARTS_WITH },
          { key: ItemProps.description, threshold: matchSorter.rankings.CONTAINS },
          { key: ItemProps.parentKey,   threshold: matchSorter.rankings.STARTS_WITH },
        ], 
      });

      // Pass results to ViewState
      viewState.setSearchHits(model, results);

      // Set timeout for view refresh
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(onWakeup, timeoutPeriod);

      // Logging
      const duration = Date.now() - start;
      logger.debug("onChange finished in %d ms: Searched %d items for '%s': %d found", 
                    duration, allItems.length, value, results.length);
    }
  }

  function onWakeup() {
    timeoutId = null;
    viewState.onEvent({ name:"ItemSearchBox" })
  }
}

export default ItemSearchBox;