import React, { Fragment, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import Logger from "../common/Logger";
import BusinessModelCanvas from "../components/canvas/BusinessModelCanvas";
import ModelCanvas from "../components/canvas/ModelCanvas";
import ItemExtendedPropsCard from "../components/cards/ItemPropertiesCard";
import ItemRelationshipSection from "../components/cards/ItemRelationshipSection";
import RatingCalcCard from "../components/cards/RatingCalcCard";
import RatingChartCard from "../components/cards/RatingChartCard";
import RatingChartCards from "../components/cards/RatingChartCards";
import RatingScaleCard from "../components/cards/RatingScaleCard";
import RatingValueCard from "../components/cards/RatingValueCard";
import TypeItemCard from "../components/cards/TypeItemCard";
import ContextMenu from "../components/control/ContextMenu";
import Editable from "../components/control/Editable";
import { DocumentLinkParent, ItemTypeIcon } from "../components/control/ItemTypeIcon";
import ToolbarAction from "../components/control/ToolbarAction";
import RatingAssessment from "../components/rating/RatingAssessment";
import RatingPeriodEndDate from "../components/rating/RatingPeriodEndDate";
import RatingValueGadget from "../components/rating/RatingValueGadget";
import { useModelContext } from "../context/ModelContext";
import { useViewContext, ViewContextProvider } from "../context/ViewContext";
import { ItemProps } from "../types/Item";
import { DocumentLink } from "./ViewLinks";

const logger = new Logger("view.DocumentView");

function DocumentView(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const { key } = useParams() as any;
  const { pathname } = useLocation();
  const [event,onEvent] = useState<any>();

  const rootKey = key || "";
  const rootItem = model.getItem(rootKey);

  // This will sync the sidebar
  React.useEffect(() => {
    if (model.has(rootKey)) {
      viewState.openTo(model, rootKey);
      viewState.onEvent({ name:"DocumentView", key:rootKey });
    }
  },[pathname]);

  if (model.isEmpty() || rootItem === undefined) {
    return <></>
  }

  logger.debug("Rendering: rootKey=%s", rootKey, rootItem, event);

  return (
    <ViewContextProvider name="NetworkView" rootKey={rootKey} level={2}>
      <Card className="jemstone-view shadow document">
        <CardHeader>
          <div className="text-nowrap d-flex align-middle">
            {rootItem &&
              <>
                <div className="h1 flex-fill">
                  <ItemTypeIcon item={rootItem} />
                  <Editable item={rootItem} property={ItemProps.Name} className="d-inline ml-1 mr-1" />
                  <DocumentLinkParent item={rootItem} />
                </div>
                <div className="d-none d-sm-inline pt-1">
                  {model.isType(rootItem) ? "Type" : <DocumentLink item={model.getItemType(rootItem)} />}
                  {' :'}
                </div>
                <div className="d-inline pt-1 ml-1">
                  <Editable item={rootItem} property={ItemProps.code} />
                </div>
                <ToolbarAction className="d-none d-sm-inline pt-1 ml-2"
                               rootKey={rootKey} docView={true} showExpandAll={true} showRefresh={true} />
              </>
            }
          </div>
        </CardHeader>
        <CardBody>
          <DocumentSections/>
        </CardBody>
      </Card>
    </ViewContextProvider>
  )

  function DocumentSections(props:any) {
    const viewState = useViewContext();
    const hasViewableChildren = viewState.hasViewableChildren(model, rootItem.key);

    return (
      <>
        <div className="float-right">
          <RatingValueGadget item={rootItem} showGauge={true} showCountBar={true} />
          <div className="text-wrap text-center text-muted text-level-4">
            { model.getMeasure(rootItem)?.name || "" }
          </div>
        </div>
        <section>
          <div>
            <h2>Description</h2>
            <div>
              <Description item={rootItem} />
            </div>
          </div>
        </section>
        { hasViewableChildren &&
          <ModelCanvasSection title={rootItem.name + " Canvas"} />
        }
        { hasViewableChildren &&
          <ChildItemsSection item={rootItem} />
        }
        { hasViewableChildren && model.isRateable(rootItem) &&
          <>
            <RatingAssessmentSection item={rootItem} docView={true} />
            <RatingChartCards item={rootItem} docView={true} />
          </>
        }
        <ItemDetailsCards item={rootItem} onEvent={onEvent} docView={true} />
      </>
    )
  }

  function ModelCanvasSection(props:any) {
    const title = props.title;
    return (
      <section className="mb-2">
        <h2 className="mb-2">{title}</h2>
        <div className="text-muted mb-2">The {rootItem.name} is comprised of the following items.</div>
        { rootItem.key.startsWith("BM-")
          ? <BusinessModelCanvas rootKey={rootKey} showKey={false} showDescription={false} docView={true} onEvent={onEvent} />
          : <ModelCanvas rootKey={rootKey} showKey={false} showDescription={false} docView={true} onEvent={onEvent} />
        }
      </section>
    )
  }

  function ChildItemsSection(props:any) {
    const item = props.item;

    return (
      <section>
        <table className="border-0 child-items">
          <tbody>
          { model.childrenSorted(item.key).map(child =>
            <tr key={child.key}>
              <td className="align-text-top pr-1">
                <ItemTypeIcon item={child} />
              </td>
              <td style={{width:"100%"}}>
                <Editable item={child} property={ItemProps.Name} className="d-inline"/>
                {' '}&#8212;{' '}
                <Description item={child} className="d-inline" />
              </td>
              <td className="rowmenu align-text-top">
                <ContextMenu key={child.key} item={child} onEvent={onEvent}/>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </section>
    );
  }

  function Description(props:any) {
    const item = props.item;
    const className = props.className || "";
    const isRatingPeriod = model.isRatingPeriod(item);
    const isRatingPeriodFolder = model.isRatingPeriodFolder(item);

    return (
      <>
      { isRatingPeriod && !isRatingPeriodFolder
        ? <RatingPeriodEndDate item={item} className="text-muted mr-1" onEvent={onEvent} />
        : <Editable item={item} property={ItemProps.description} 
                    className={"text-muted text-wrap " + className} />
      }
      { model.hasErrors(item.key) &&
        <div className="error mt-1">
          { model.getErrors(item.key)?.map((err,i) =>
            <div key={i}>Error: {err.message}</div>
          )}
        </div>
      }
      </>
    )
  }

  function RatingAssessmentSection(props:any) {
    const item = props.item;
    const itemType = model.getItemType(item);
    const ratingPeriodFolder = model.getItem(model.getRatingPeriodFolderKey());
    
    return (
      <section>
        <h2>Rating Assessments</h2>
        <div className="text-muted mb-2">
          This table illustrates the rating assessments for <DocumentLink item={itemType}/>'s over 
          all <DocumentLink item={ratingPeriodFolder}/>.
        </div>
        <RatingAssessment items={model.childrenSorted(item.key)} />
      </section>
    )
  }
}

export function ItemDetailsCards(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const item = props.item;
  const docView = props.docView || false;

  const [event, onEvent] = useState<any>();
  const hasViewableChildren = viewState.hasViewableChildren(model, item.key);

  const logger = new Logger("view.ItemDetailsCards");

  logger.debug("Rendering:", event);

  return (
    <Fragment key={item.key}>
      { model.isMeasure(item) &&
        <>
          <RatingScaleCard item={item} onEvent={onEvent} docView={docView} />
          <RatingCalcCard  item={item} onEvent={onEvent} docView={docView} />
          <RatingChartCard item={item} onEvent={onEvent} docView={docView} />
        </>
      }
      { model.isType(item) &&
        <TypeItemCard item={item} onEvent={onEvent} docView={docView} />
      }
      { (!docView || !hasViewableChildren) && model.isRateable(item) &&
        <RatingValueCard item={item} onEvent={onEvent} docView={docView} />
      }
      { !model.isRatingPeriodFolder(item) &&
        <ItemRelationshipSection item={item} onEvent={onEvent} docView={docView} />
      }
      <ItemExtendedPropsCard item={item} onEvent={onEvent} docView={docView} />
    </Fragment>
  )
}

export default DocumentView;
