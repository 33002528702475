import React, { useState } from 'react';
import Logger from "../../common/Logger";
import { formatNumber } from '../../common/utils';
import { useModelContext } from "../../context/ModelContext";
import { useViewContext } from "../../context/ViewContext";
import { Item, ItemProps } from "../../types/Item";
import { RatingMeasure } from '../../types/RatingMeasure';
import { RatingMeasureUtils } from '../../types/RatingMeasureHelper';
import { ItemDetailsCards } from '../../views/DocumentView';
import { DocumentLink } from '../../views/ViewLinks';
import ContextMenu from '../control/ContextMenu';
import Editable from '../control/Editable';
import { ItemTypeIcon } from '../control/ItemTypeIcon';
import DebugRatingValue from '../debug/DebugRatingValue';
import RatingPeriodEndDate from '../rating/RatingPeriodEndDate';

const logger = new Logger("component.ModelRow");

function ModelRow(props:any) {
  const context = useModelContext();
  const model = context.model;
  const viewState = useViewContext();
  const level = props.level;
  const onEvent = props.onEvent;
  const showKey = false;
  const hideDescription = props.hideDescription;
  const isExpandable = props.expandable;
  const showRawScores = props.showRawScores;
  const showMaxScore = props.showMaxScore;

  const itemState = viewState.get(props.item.key);

  const [item, setItem] = useState<Item>(props.item);
  const [isOpenItem, onOpenItem] = useState(itemState.openItem);
  const isSelected = itemState.selected;
  const isOpenTree = itemState.openTree;
  const isSearchHit = itemState.searchHit;
  const hasErrors = model.hasErrors(props.item.key);

  const numColumns = 6 + model.getRatingPeriods().length;
  const classRow = hasErrors   ? "error"      :
                   isSearchHit ? "search-hit" : 
                   isSelected  ? "selected"   : 
                   isOpenItem  ? "open-item"  : "";

  logger.debug("Rendering: key=%s, isSelected=%s, isOpenTree=%s, isOpenItem=%s, isSearchHit=%s", 
                item.key, isSelected, isOpenTree, isOpenItem, isSearchHit, item);

  return (
    <>
      <RowItem/>
      { isOpenItem && <RowItemOpen/> }
    </>
  );

  function onChangeRow(event:any) {
    setItem(model.getItem(item.key));
  }

  function RowItem(props:any) {
    const ratingModel = model.getRatingModel();
    const isMeasure = model.isMeasure(item);
    const isRatingPeriod = model.isRatingPeriod(item);
    const isRatingPeriodFolder = model.isRatingPeriodFolder(item);
    const isRatingValue = model.isRatingValue(item);
    const isType = model.isType(item);

    const measures = getMeasures(item);
    const widthButton = 1.5;
    const styleIndent1 = { paddingLeft: widthButton * level + "rem" }
    const styleIndent2 = { paddingLeft: widthButton * (level+1) + "rem" }

    return (
      <tr className={classRow + " text-level-" + (level+1)}>
        <td className={ viewState.showSelectCheckbox ? "pl-0 rowmenu" : "d-none" }>
          <SelectCheckbox />
        </td>

        {/* Name and Description in 1 column: user selected or responsive at lg breakpoint */}
        <td className="pl-0">
          <Name item={item}/>
          <Description item={item} className={ hideDescription ? "" : "d-lg-none" }
                       style={styleIndent2}/>
        </td>

        {/* Description: responsive to merge with name at lg breakpoint */}
        <td className={ hideDescription ? "d-none" : "d-none d-lg-table-cell" }>
          { !isRatingValue
            ? <Description item={item}/>
            : <DebugRatingValue item={item}/>
          }
        </td>

        {/* Rating: content varies with item type */}
        <td>
          { measures.map((measure, i) =>
            <MeasureListItem measure={measure}/>
          )}
          { isMeasure && <MeasureListItem measure={item}/> }
        </td>

        {/* Raw Rating: display the raw rating scores for each period, and the maximum possible score */}
        { showRawScores && model.getRatingPeriods().map(period =>
          <td className="d-none d-lg-table-cell text-muted value" key={period.key}>
            { measures.map(measure =>
              <div key={measure.key} title={measure.name}>
                { formatNumber(getValue(measure.key, period.key)) }
              </div>
            )}
          </td>
        )}
        { showRawScores && showMaxScore &&
          <td className="d-none d-lg-table-cell text-muted value">
            { measures.map(measure =>
              <div key={measure.key} title={measure.name}>
                { formatNumber(ratingModel.getMaxScore(item.key, measure.key)) }
              </div>
            )}
          </td>
        }

        { /* Context Menus */}
        <td className="d-none d-sm-table-cell rowmenu">
          <OpenItemButton item={item} />
          <ContextMenu item={item} onEvent={onEvent} />
        </td>
      </tr>
    );

    function getValue(measureKey:string, periodKey:string) : number {
      const ratingValue = ratingModel.getValue(item.key, measureKey, periodKey, true);
      return ratingValue.value;
    }

    function MeasureListItem(props:any) {
      const measure:RatingMeasure = props.measure;

      return (
        <div className="text-muted text-level-4">
          { !isMeasure &&
            <DocumentLink item={measure}/>
          }
          {/* <ul>
            <li>{measure.ratingScale.name}</li>
            <li>{measure.ratingCalc?.name}</li>
            <li>{measure.chartType?.name}</li>
          </ul> */}
        </div>
      )
    }

    function Name(props:any) {
      const item = props.item;
      const className = "d-flex h" + (level+3);
      const styleItemKey = { width: "3rem" }
      const title = "key:" + item.key
                  + ", type:" + item.typeKey 
                  + ", parent:" + item.parentKey;
   
      return (
        <div title={title} className={className} style={styleIndent1}>
          <ExpandButton width={widthButton} />
          <ItemTypeIcon item={item} />
          { showKey &&
            <span className="mr-1" style={styleItemKey}>{item.key}</span>
          }
          <Editable item={item} property={ItemProps.Name} className="flex-fill ml-1" />
        </div>
      );
    }

    function Description(props:any) {
      const item = props.item;
      const style = props.style;
      const className = props.className || "";

      return (
        <div className={"d-flex " + className} style={style}>
          <div className="flex-fill">
            { isRatingPeriod && !isRatingPeriodFolder
              ? <RatingPeriodEndDate item={item} className="text-muted mr-1" onEvent={onChangeRow} />
              : <Editable item={item} property={ItemProps.description} 
                          className="text-muted text-wrap" />
            }
            { isMeasure && RatingMeasureUtils.hasCalc(item) &&
              <div className="text-muted text-wrap mt-2">
                <i>{item.ratingCalc.name} &#8212; {item.ratingCalc.description}</i>
              </div>
            }
            { model.hasErrors(item.key) &&
              <div className="error mt-1">
                { model.getErrors(item.key)?.map((err,i) =>
                  <div key={i}>Error: {err.message}</div>
                )}
              </div>
            }
          </div>
        </div>
      );
    }

    /**
     * Return array of RatingMeasure's defined for the specified TypeItem
     * @param item a TypeItem
     */
    function getMeasures(item:any /*TypeItem*/) : RatingMeasure[] {
      return isType ? model.getItemArray<RatingMeasure>(item.measureKeys)
                    : model.getMeasuresForType(item);
    }
  }

  function RowItemOpen(props:any) {
    return (
      <tr className={classRow}>
        <td className="open-item-contents" colSpan={numColumns}>
          <ItemDetailsCards key={item.key} item={item} onEvent={onEvent} />
        </td>
      </tr>
    );
  }

  function ExpandButton(props:any) {
    const classImage = !isExpandable ? "far fa-angle-right text-muted" :
                        isOpenTree   ? "far fa-angle-down" : "far fa-angle-right";

    return (
      <div className="inline-block">
        <button className="btn-image" onClick={toggleOpenTree}>
          <i className={classImage} />
        </button>
      </div>
    );

    function toggleOpenTree() {
      const itemState = viewState.toggleOpenTree(item.key);
      if (itemState.openTree) {
        viewState.openTo(model, item.key);
      }
      viewState.onEvent({name:"toggleOpenTree", key:item.key, value:itemState.openTree});
    }
  }  

  function SelectCheckbox(props:any) {
    const classImage = (isSelected ? "far fa-check-circle" : "far fa-circle");

    return (
      <button className="btn-image" onClick={onClick}>
        <i className={classImage} />
      </button>
    );

    function onClick() {
      const recursive = !isOpenTree;
      const itemState = viewState.setSelected(context.model, item.key, !isSelected, recursive);
      viewState.onEvent(itemState);
    }
  }

  function OpenItemButton(props:any) {
    const classImage = (isOpenItem ? "far fa-angle-double-up" : "far fa-angle-double-down");

    return (
      <button className="btn-image" onClick={onClick}>
        <i className={classImage} />
      </button>
    );

    function onClick() {
      onOpenItem(viewState.toggleOpenItem(item.key).openItem);
    }
  }
}

export default ModelRow;