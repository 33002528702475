import {nanoid } from 'nanoid';
import Logger from "../common/Logger";
import { Item, ItemStatus } from "./Item";
import { RatingMeasure } from "./RatingMeasure";
import { RatingModel } from './RatingModel';
import { RatingPeriod, RatingValue } from "./RatingValue";
import { TypeItem } from "./TypeItem";

const logger = new Logger("types.Model");

export interface ModelError {
  key: string;
  message: string;
  item: Item;
}

/**
 * Container for a model of many items
 */
export class Model {
  /** Map of items keyed by Item.key */
  private itemMap = new Map<string,Item>();

  /** Cached array of all items in the map */
  private itemArray:Item[] = [];

  /** Map containing an array of children keys for each item, keyed by Item.parentKey */
  private childrenMap = new Map<string,string[]>();

  /** Map containing an array of children for each type, keyed by Item.type */
  private typeMap = new Map<string,string[]>();

  /** Map containing an array of item keys that reference the key item (using Item.links) */
  private linksMap = new Map<string,string[]>();

  /** Map Item.code to Item.key */
  private codeMap = new Map<string,string>();

  /** RatingModel to manage multi-dimensional ratings */
  private ratingModel = new RatingModel(this);

  /** Model validation errors */
  private modelErrors = new Map<string,ModelError[]>();

  /** Clear the model of all items */
  public clear() {
    this.itemMap.clear();
    this.childrenMap.clear();
    this.typeMap.clear();
    this.linksMap.clear();
    this.codeMap.clear();
    this.itemArray = [];
    this.ratingModel = new RatingModel(this);
    this.modelErrors.clear();
  }

  public isEmpty(): boolean {
    return this.itemMap.size <= 0;
  }

  public has(key:string): boolean {
    return this.itemMap.has(key);
  }

  public size(): number {
    return this.itemMap.size;
  }

  public keys(): string[] {
    return Array.from(this.itemMap.keys());
  }

  public getItems(): Item[] {
    if (this.itemArray.length === 0) {
      this.itemArray = Array.from(this.itemMap.values());
    }
    return this.itemArray;
  }

  public getItem<T extends Item>(key:string) : T {
    const item:any = this.itemMap.get(key);
    const t:T = item;
    return t;
  }

  public setItems(items:Item[], isReload:boolean) : Model {
    items.forEach(item => this.setItem(item, isReload));

    // Log all validation errors
    if (isReload && this.modelErrors.size > 0) {
      logger.warn("setItems: Validation errors detected:", this.getErrorsAll());
    }
    return this;
  }

  public setItem<T extends Item>(item:T, isReload:boolean = false) : T {
    try {
      // Check for invalid item
      this.validateItem(item);

      // Clear array
      if (this.itemArray.length > 0) {
        this.itemArray = [];
      }

      // If status is DELETED then remove
      if (item.status === ItemStatus.DELETED) {
        return this.removeItem<T>(item);
      }
      
      // Handle change of parentKey
      const oldItem = this.getItem(item.key);
      if (oldItem !== undefined && oldItem.parentKey !== item.parentKey) {
        this.removeFromArray(oldItem.key, this.childrenMap.get(oldItem.parentKey));
      }

      // Add to all items map
      this.itemMap.set(item.key, item);

      // Add item to parent's list of children if required
      let childKeys = this.childrenMap.get(item.parentKey);
      if (childKeys === undefined) {
        childKeys = [];
        this.childrenMap.set(item.parentKey, childKeys);
      }
      if (isReload || childKeys.indexOf(item.key) === -1) {
        childKeys.push(item.key);
      }

      // Add item to the typeMap
      let typeKeys = this.typeMap.get(item.typeKey);
      if (typeKeys === undefined) {
        typeKeys = [];
        this.typeMap.set(item.typeKey, typeKeys);
      }
      if (isReload || typeKeys.indexOf(item.key) === -1) {
        typeKeys.push(item.key);
      }

      // Update the linksMap if required
      this.updateLinksMap(item, isReload);

      // Update codeMap if code is present
      if (item.code !== undefined) {
        this.codeMap.set(item.code, item.key);
      }

      // If a RatingValue then add to the RatingModel
      if (this.isRatingValue(item)) {
        const value:any = item;
        this.ratingModel.setValue(value, isReload);
      }

      // Return the item just updated
      return item;
    } catch (e) {
      logger.error("setItem: Error setting item:", item, e);
      throw e;
    }
  }

  /** 
   * Remove an item from the model, updating all internal data structures
   */
  private removeItem<T extends Item>(item:T) : T {
    const key = item.key;

    // If a RatingValue then add to the RatingModel
    if (this.isRatingValue(item)) {
      const value:any = item;
      this.ratingModel.removeValue(value);
    }

    // Remove from main map
    this.itemMap.delete(key);

    // Children map, and from parent item's array of children
    this.childrenMap.delete(key);
    this.removeFromArray(key, this.childrenMap.get(item.parentKey));

    // Type map
    this.removeFromArray(key, this.typeMap.get(item.typeKey));

    // Code map
    if (item.code !== undefined) {
      this.codeMap.delete(item.code);
    }

    // Return the item just removed
    return item;
  }

  private updateLinksMap(item:Item, isReload:boolean) {
    if (!item.links || item.links.length === 0) {
      return;
    }

    for (const targetKey of item.links) {
      let targetLinkMapKeys = this.linksMap.get(targetKey);
      if (targetLinkMapKeys === undefined) {
        targetLinkMapKeys = [];
        this.linksMap.set(targetKey, targetLinkMapKeys);
      }
      if (isReload || targetLinkMapKeys.indexOf(item.key) === -1) {
        targetLinkMapKeys.push(item.key);
      }
    }

    logger.trace("updateLinksMap: key=%s, links:", item.key, item.links);
  }

  public children<T extends Item>(key:string) : T[] {
    const keys = this.childrenMap.get(key);
    return (keys !== undefined) ? this.getItemArray<T>(keys) : [];
  }

  public childrenSorted<T extends Item>(key:string) : T[] {
    return this.sortItems(this.children(key));
  }

  public childrenDeep<T extends Item>(key:string) : T[] {
    return this.getItemArray<T>(this.childrenKeysDeep(key));
  }

  public childrenKeys(key:string): string[] {
    const keys = this.childrenMap.get(key);
    return (keys !== undefined) ? keys : [];
  }

  public relatedKeys(key:string): string[] {
    const item = this.getItem(key);
    const outboundKeys = item?.links || [];
    const inboundKeys  = this.linksMap.get(key) || [];
    const childrenKeys = this.childrenMap.get(key) || [];

    // logger.debug("relatedKeys: key=%s, outbound, inbound, children:", 
    //               key, outboundKeys, inboundKeys, childrenKeys);

    // Combine all arrays, and eliminate duplicates
    const keys = [...inboundKeys, ...childrenKeys, ...outboundKeys];
    return keys.filter((key,index) => keys.indexOf(key) === index);
  }

  public childrenKeysDeep(key:string, keys:string[]=[]): string[] {    
    const ckeys = this.childrenMap.get(key);
    if (ckeys !== undefined) {
      for (const ckey of ckeys) {
        if (keys.indexOf(ckey) === -1) {
          keys.push(ckey);
        }
        this.childrenKeysDeep(ckey, keys);
      }
    }
    return keys;
  }

  /**
   * Return a list of children keys the specified number of levels deep
   * @param key 
   * @param levels Number of levels deep - 1 equals immediate children, 2 = grandchildren, etc
   * @param level 
   * @param keys 
   */
  public childrenKeysLevel(key:string, levels:number=999, level:number=1, keys:string[]=[]): string[] {    
    const ckeys = this.childrenMap.get(key);
    if (ckeys !== undefined) {
      for (const ckey of ckeys) {
        if (keys.indexOf(ckey) === -1) {
          keys.push(ckey);

          if (level < levels) {
            this.childrenKeysLevel(ckey, levels, level+1, keys);
          }
        }
      }
    }
    return keys;
  }

  /**
   * Return an array of keys of children at the specified levels below the parent key specified
   * @param key 
   * @param levels Number of levels below - 1 equals immediate children, 2 = grandchildren, etc
   * @param level 
   * @param keys 
   */
  public childrenKeysAtLevel(key:string, levels:number=1, level:number=1, keys:string[]=[]): string[] {    
    const ckeys = this.childrenMap.get(key);
    if (ckeys !== undefined) {
      for (const ckey of ckeys) {
        if (level === levels && keys.indexOf(ckey) === -1) {
          keys.push(ckey);
        }

        if (level < levels) {
          this.childrenKeysLevel(ckey, levels, level+1, keys);
        }
      }
    }
    return keys;
  }

  public hasChildren(key:string) {
    return this.childrenKeys(key).length > 0;
  }

  public types(): string[] {
    return Array.from(this.typeMap.keys());
  }

  public hasErrors(key:string) {
    return this.modelErrors.has(key);
  }

  public getErrors(key:string) {
    return this.modelErrors.get(key);
  }

  public getErrorsAll() : ModelError[] {
    const results:ModelError[] = [];

    for (const errors of this.modelErrors.values()) {
      for (const error of errors) {
        results.push(error);
      }
    }

    return results;
  }

  public addError(key:string, item:Item, message:string) {
    let errors = this.modelErrors.get(key);
    if (errors === undefined) {
      errors = [];
      this.modelErrors.set(key, errors);
    }

    errors.push({
      key: key,
      message: message,
      item: item
    });
  }

  private clearErrors(key:string) {
    let errors = this.modelErrors.get(key);
    if (errors !== undefined) {
      errors.length = 0;
    }
  }

  private validateItem(item:Item) : boolean {
    this.clearErrors(item.key);

    let valid = true;
    if (item === undefined) {
      logger.warn("isValid: item is undefined");
      valid = false;
    }
    if (item.key === undefined) {
      this.addError(item.key, item, "item.key is undefined");
      valid = false;
    }
    if (item.status === undefined) {
      this.addError(item.key, item, "item.status is undefined");
      valid = false;
    }
    if (item.parentKey === undefined) {
      this.addError(item.key, item, "item.parentKey is undefined");
      valid = false;
    }
    if (item.typeKey === undefined && !this.isType(item)) {
      this.addError(item.key, item, "item.typeKey is undefined");
      valid = false;
    }
    if (this.isMeasure(item)) {
      const measure:any = item;
      valid = valid && this.validateMeasure(measure);
    }
    return valid;
  }

  private validateMeasure(measure:RatingMeasure) : boolean {
    let valid = true;

    if (measure.ratingCalc === undefined) {
      this.addError(measure.key, measure, "measure.ratingCalc is undefined");
      valid = false;
    }
    if (measure.ratingScale === undefined) {
      this.addError(measure.key, measure, "measure.ratingScale is undefined");
      valid = false;
    }
    if (measure.chartType === undefined) {
      this.addError(measure.key, measure, "measure.chartType is undefined");
      valid = false;
    }
    return valid;
  }

  public getItemByCode<T extends Item>(code: string) : T | undefined {
    const key = this.codeMap.get(code);
    if (key !== undefined)
      return this.getItem<T>(key);

    return undefined;
  }

  /**
   * Return an array of all items where Item.typeKey === typeKey
   * @param typeKey 
   */
  public getItemsByType<T extends Item>(typeKey: string) : T[] {
    const keys = this.typeMap.get(typeKey);
    return (keys !== undefined) ? this.getItemArray<T>(keys) : [];
  }

  /**
   * Return an array of items for each key in the specified list
   * @param keys
   */
  public getItemArray<T extends Item>(keys?:string[]): T[] {
    return (keys !== undefined) ? keys.map(key => this.getItem<T>(key)) : [];
  }

  private removeFromArray(key:string, keys?:string[]) {
    if (keys !== undefined) {
      const index = keys.indexOf(key);
      if (index !== -1) {
        keys.splice(index, 1);
      }
    }
  }

  /**
   * Return the parent (Item) of the specified item
   * @param item 
   */
  public getItemParent(item:Item) : Item {
    return this.getItem<Item>(item.parentKey);
  }

  /**
   * Return the type (TypeItem) of the specified Item
   * @param item 
   */
  public getItemType(item:Item) : TypeItem {
    return this.getItem<TypeItem>(item?.typeKey);
  }

  public static KeyPrefixType = "ZZ-";
  public static KeyPrefixRatingModel = "RT-";
  public static KeyPrefixRatingValue = "XX-";

  public getRatingValueFolderKey() : string {
    return this.codeMap.get("RT-9000") as string;
  }

  public getRatingPeriodFolder() : Item {
    return this.getItem(this.getRatingPeriodFolderKey());
  }

  public getRatingPeriodFolderKey() : string {
    return this.codeMap.get("RT-7000") as string;
  }

  public getTypeModelRootKey() : string {
    return this.codeMap.get("ZZ-0000") as string;
  }

  public getTypeRatingMeasureKey() : string {
    return this.codeMap.get("ZZ-1800") as string;
  }

  public getTypeRatingPeriodKey() : string {
    return this.codeMap.get("ZZ-1900") as string;
  }

  public getTypeRatingValueKey() : string {
    return this.codeMap.get("ZZ-4100") as string;
  }

  public getTypeRatingModelKey() : string {
    return this.codeMap.get("ZZ-4000") as string;
  }

  public isType(item:Item): boolean {
    return item?.key.startsWith(Model.KeyPrefixType);
  }

  public isTypeModel(item:Item): boolean {
    return item?.key === this.getTypeModelRootKey();
  }

  public isRatingModel(item:Item): boolean {
    return item?.key.startsWith(Model.KeyPrefixRatingModel) || 
           item?.key.startsWith(Model.KeyPrefixRatingValue) || 
           item?.typeKey === this.getTypeRatingModelKey();
  }

  public isRateable(item:Item): boolean {
    return (item !== undefined) && this.isRateableKey(item.key);
  }

  public isRateableKey(itemKey:string) : boolean {
    return (itemKey !== undefined && itemKey !== "") &&
          !(itemKey.startsWith(Model.KeyPrefixRatingModel) || 
            itemKey.startsWith(Model.KeyPrefixRatingValue) || 
            itemKey.startsWith(Model.KeyPrefixType)
           );
  }

  public hasRateableChildren(itemKey:string) : boolean {
    const childKeys = this.childrenKeys(itemKey);
    const index = childKeys.findIndex(key => this.isRateableKey(key));
    return (index !== -1);
  }

  public isMeasure(item:Item): boolean {
    return item?.typeKey === this.getTypeRatingMeasureKey();
  }

  public isRatingPeriod(item:Item): boolean {
    return item?.typeKey === this.getTypeRatingPeriodKey();
  }

  public isRatingPeriodFolder(item:Item): boolean {
    return this.isRatingPeriod(item) && this.hasChildren(item.key);
  }

  public isRatingValue(item : Item): boolean {
    return item.key.startsWith(Model.KeyPrefixRatingValue);
  }

  public isRatingValueKey(itemKey : string): boolean {
    return itemKey.startsWith(Model.KeyPrefixRatingValue);
  }

  public getTypeItems(): TypeItem[] {
    return this.childrenDeep<TypeItem>(this.getTypeModelRootKey());
  }

  public getRatingModel(): RatingModel {
    return this.ratingModel;
  }

  public getMeasures(): RatingMeasure[] {
    return this.getItemsByType<RatingMeasure>(this.getTypeRatingMeasureKey());
  }

  public getMeasure(item:Item) : RatingMeasure | undefined {
    const measures = this.getMeasuresForType(item);
    if (measures === undefined || measures.length === 0) {
      return undefined;
    }
    return measures[0];
  }

  public getMeasureKey(item:Item) : string {
    const measure = this.getMeasure(item);
    return measure ? measure.key : "UNKNOWN";
  }

  public getMeasuresForType(item:Item): RatingMeasure[] {
    const typeItem = this.getItemType(item);
    if (typeItem === undefined) {
      logger.warn("getMeasuresForType: Cannot find type for item:", item);
      return [];
    }
    const measures = this.getItemArray<RatingMeasure>(typeItem.measureKeys);
    return measures;
  }

  public getRatingPeriods(): RatingPeriod[] {
    return this.getItemsByType<RatingPeriod>(this.getTypeRatingPeriodKey()).filter(item => item.periodEndDate !== undefined);
  }

  public getRatingPeriodForDate(date:number) : RatingPeriod {
    const periods = this.getRatingPeriods();
    let i=0;
    for (i=0; i < periods.length; i++) {
      const period = periods[i];
      if (date < period.periodEndDate) {
        return period;
      }
    }
    return periods[i-1];
  }

  public getRatingValues(): RatingValue[] {
    return this.getItemsByType<RatingValue>(this.getTypeRatingValueKey());
  }

  public sortItems<T extends Item>(items:T[]) : T[] {
    return items.sort((i1,i2) => this.sortKey(i1).localeCompare(this.sortKey(i2)));
  }
  
  private sortKey(item:Item) : string {
    const itemType = this.getItemType(item);
    if (itemType === undefined) {
      return Model.KeyPrefixType + "9999" + item.sortOrder;
    }
    if ((item.sortOrder || "") !== "") {
      return item.sortOrder + item.name;
    }
    return itemType.sortOrder + item.name;
  }

  public sortByName<T extends Item>(items:T[]) : T[] {
    return items.sort((i1,i2) => i1.name.localeCompare(i2.name));
  }

  public static readonly root:Item = {
    key: "",
    name: "-",
    description: "",
    parentKey: "",
    typeKey: "",
    sortOrder: 0,
    modifiedDate: 0,
    status: ItemStatus.TRANSIENT
  }

  public newKey(parentKey:string, prefix?:string) : string {
    // Find the hyphen in the parentKey XX-####
    if (prefix === undefined) {
      const hyphen = parentKey.indexOf("-");
      prefix = parentKey.substr(0, hyphen+1);
    }

    // Create the new key
    const newKey = prefix + nanoid(6);
    return newKey;
  }

  /**
   * Factory method to create a new item with the specified parent. A new key is generated as
   * the next available number in the list of children.
   * @param parentKey The parentKey for the new item in form "XX-####"
   */
  public newItem(parentKey:string): any {
    // Allocate a key for the new item
    const newKey = this.newKey(parentKey);

    // If type is not defined then use type of parent
    const parent = this.getItem(parentKey);
    const typeKey = (parent !== undefined) ? parent.typeKey : this.getTypeModelRootKey();

    const item:Item = {
      key: newKey,
      name: "",
      description: "",
      parentKey: parentKey,
      typeKey: typeKey,
      sortOrder: parent?.sortOrder || 0,
      status: ItemStatus.NEW,
      modifiedDate: Date.now(),
    };

    return item;
  }
}

/**
 * Enable compile time checking of property name
 * @param obj 
 * @param key 
 */
export function getPropName<T, K extends keyof T>(obj: T, key: K): K {
  return key;
}

export function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];  // Inferred type is T[K]
}

export function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
  obj[key] = value;
}
