import React from "react";
import ItemSearchBox from "../components/control/ItemSearchBox";
import { Link } from "react-router-dom";

function JemstoneHeader(props:any) {
  const isExpanded = props.expanded;
  const setExpanded = props.setExpanded;
  const isResponsive = props.responsive;
  const setResponsive = props.setResponsive;

  const logoSrc = require("../assets/jemstone-logo.png");
  const logoStyle = { width:"13rem" }
  const iconClass = isExpanded ? "far fa-angle-double-left" : "far fa-angle-double-right";

  return (
    <div className="d-flex mt-2 mr-2">
      <div className="pt-2">
        <button className="btn-image jemstone-sidebar-open" onClick={() => setExpanded(!isExpanded)}>
          <i className={iconClass}></i>
        </button>
        <button className="btn-image jemstone-sidebar-menu" onClick={() => setResponsive(!isResponsive)}>
          <i className="far fa-bars"></i>
        </button>
      </div>
      <div className="pl-1 flex-fill">
        <Link to="/">
          <img src={logoSrc} style={logoStyle} alt="" />
        </Link>
      </div>
      <div>
        <ItemSearchBox />
      </div>
    </div>
  )
}

export default JemstoneHeader;