import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ModelContextProvider } from "./jemstone/context/ModelContext";
import { ViewContextProvider } from './jemstone/context/ViewContext';
import JemstoneLayout from './jemstone/layouts/JemstoneLayout';
import JemstoneHomeLayout from './jemstone/layouts/JemstoneHomeLayout';

import "@fortawesome/fontawesome-pro/css/all.min.css";
import './jemstone/assets/css/jemstone.bootstrap.css';
import "./jemstone/assets/css/jemstone.css";

// Create the app
const app = (
  <ModelContextProvider>
    <ViewContextProvider name="App">
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard" render={props => <JemstoneLayout {...props} sidebar={false} />} />
          <Route path="/document"  render={props => <JemstoneLayout {...props} />} />
          <Route path="/table"     render={props => <JemstoneLayout {...props} />} />
          <Route path="/canvas"    render={props => <JemstoneLayout {...props} />} />
          <Route path="/network"   render={props => <JemstoneLayout {...props} />} />
          <Route path="/home"      render={props => <JemstoneHomeLayout {...props}/>} />
          <Redirect from="/" to="/home" />
        </Switch>
      </BrowserRouter>
    </ViewContextProvider>
  </ModelContextProvider>
);

export default app;