import React from "react";
// import { Card, CardBody, CardDeck, CardHeader, CardTitle } from "reactstrap";
import Logger from "../common/Logger";
import { useModelContext } from "../context/ModelContext";
import { CanvasLink, DashboardLink, DocumentLink, NetworkLink, TableLink } from "./ViewLinks";
// import { ReactComponent as BusArchSVG } from "../assets/bus-arch.svg";

const logger = new Logger("view.BusinessModelView");

function HomePage(props: any) {
  const context = useModelContext();

  logger.debug("Rendering:", props);

  return (
    <div className="jemstone-home">
      <section id="agility" className="content agility" >
        <BusinessAgility />
      </section>
      <section id="strategy" className="content strategy">
        <StrategicPlanning />
      </section>
      <section id="features" className="content features">
        <Features />
      </section>
      {/* <section id="pricing" className="content pricing">
        <Pricing />
      </section> */}
      <section id="about" className="content about">
        <About />
      </section>
    </div>
  );

  function BusinessAgility(props: any) {
    const className = props.className || "";
    const imgSrc = require("../assets/images/business-agility.jpg");

    return (
      <div className={className}>
        <div className="title text-center">
          Building an Agile Business
        </div>
        <div>
          <img src={imgSrc} className="float-right" alt="" />
          <p>
            Business Agility is the ability to compete and thrive in the digital age by quickly responding to
            market changes and emerging opportunities with innovative business solutions. 
            Lean and Agile practices continually deliver innovative, high-quality products and services
            faster than the competition.
            <br/>
            <a href="https://www.scaledagileframework.com/business-agility/" style={{fontSize: "8pt"}}>
              © Scaled Agile, Inc.
            </a>
          </p>
          <p>
            Having a strategy that sets a north star for your business is key to enabling agility in the face of 
            known and emerging threats - it guides the evolution of your business model and sets the guard rails 
            within which good operational decisions can be made.
          </p>
          <p>
            Jemstone is a collaborative online platform for developing and tracking the <em>execution</em> of 
            a business strategy that can elevate your business to the next level.
          </p>
        </div>
      </div>
    )
  }

  function StrategicPlanning(props: any) {
    const className = props.className || "";
    const imgSrc = require("../assets/images/strategic-planning.jpg");

    return (
      <div className={className}>
        <div className="title text-center">
          Developing an Executable Strategy
        </div>
        <div>
          <img src={imgSrc} className="float-right" alt="" />
          <p>
            Many organisations face challenges when determining how to respond to threats to their existing
            business model, threats from digital natives, emerging competitors, erosion of markets, pandemics, etc.
          </p>
          <p>
            Typically a strategic plan is created as a document or slide deck which is almost immediately out of date. 
          </p>
          <p>
            Jemstone provides an alternate approach - discrete elements of your business are represented as data items, each 
            of which have measures attached that allow the effectiveness of your strategic initiatives to be quantified.
            If an initiative isn't moving the dial, changes can be made - this is business agility!
          </p>
          <p>
            The key elements that must be considered when developing your strategy and strategic plan are illustrated below.
          </p>
          <div className="row">
            <div className="col-md">
              <BusArchSVG />
            </div>
            <div className="col-md">
              <ul>
                <ListItem itemCode={context.codeValues.businessStrategyCode} />
                <ListItem itemCode={context.codeValues.businessModelCode} />
                <ListItem itemCode={context.codeValues.capabilityModelCode} />
                <ListItem itemCode={context.codeValues.opModelCode} />
                <ListItem itemCode={context.codeValues.portfolioModelCode} />
                <ListItem itemCode={context.codeValues.riskModelCode} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    )

    function ListItem(props:any) {
      const item = context.model.getItemByCode(props.itemCode);
      if (item === undefined) 
        return <></>;

      return (
        <li key={item.key} className="mt-1">
          {item.description}
        </li>
      )
    }

    function BusArchSVG(props:any) {
      const rx=125, ry=70;
      return (
        <svg viewBox="0 0 500 350" xmlns="http://www.w3.org/2000/svg" version="1.1" baseProfile="full">
          <Ellipse cx={250} cy={175} rx={250} ry={175} ty={330} fill="rgba(168,168,168, 0.5)" words={["Risk Model"]}
                  itemKey={context.codeValues.riskModelCode} />
          <Ellipse cx={250} cy={240} rx={rx} ry={ry} ty={265} fill="rgb(150,152,233, 0.8)" words={["Operating","Model"]}
                  itemKey={context.codeValues.opModelCode} />
          <Ellipse cx={350} cy={175} rx={rx} ry={ry} tx={390} fill="rgb(179,54,54, 0.6)" words={["Capability","Model"]}
                  itemKey={context.codeValues.capabilityModelCode} />
          <Ellipse cx={150} cy={175} rx={rx} ry={ry} tx={110} fill="rgb(255,92,92, 0.8)" words={["Business","Model"]}
                  itemKey={context.codeValues.businessModelCode} />
          <Ellipse cx={250} cy={100} rx={rx} ry={ry} ty={80} fill="rgb(255,189,74, 0.7)" words={["Business","Strategy"]}
                  itemKey={context.codeValues.businessStrategyCode} />
          <Ellipse cx={250} cy={175} rx={ry+20} ry={ry-20} fill="rgba(74, 255, 98, 0.712)" words={["Investment","Portfolio"]}
                  itemKey={context.codeValues.portfolioModelCode} />
        </svg>
      )

      function Ellipse(props:any) {
        const { cx, cy, rx, ry, fill, itemKey, words } = props;
        const tx = props.tx || cx;
        const ty = props.ty || cy;

        return (
          <DocumentLink itemKey={itemKey}>
            <ellipse cx={cx} cy={cy} rx={rx} ry={ry} fill={fill} />
            { words.map((word:any, i:number) =>
              <text key={i} x={tx} y={ty + i*20} fontSize="15" textAnchor="middle" fill="white">
                {word}
              </text>
            )}
          </DocumentLink>
        )
      }
    }
  }

  function Features(props: any) {
    const className = props.className || "";

    return (
      <div className={className}>
        <div className="title text-center mb-4">
          Features
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 row-cols-xxxl-6">
          <div className="col">
            <h2 className="text-nowrap mb-0">
              <i className="fal fa-clock mr-2" />
              Visualise
            </h2>
            <div className="body">
              <ul>
                <li><DashboardLink>Dashboard</DashboardLink> overview of top-level measures with drilldown</li>
                <li><DocumentLink itemKey={context.codeValues.businessModelCode}>Document view</DocumentLink> of item details</li>
                <li><CanvasLink itemKey={context.codeValues.businessModelCode}>Canvas views</CanvasLink> - business model, capability model, lean canvas</li>
                <li><TableLink>Tabular view</TableLink> - spreadsheet like view of all items</li>
                <li><NetworkLink>Network graph</NetworkLink> of related items</li>
                <li>Drilldown, drill-up and drill-across everywhere</li>
                <li>Range of chart types to visualise measures</li>
              </ul>
            </div>
          </div>
          <div className="col">
            <h2 className="text-nowrap mb-0">
              <i className="fal fa-clock mr-2" />
              Configure
            </h2>
            <div className="body">
              <ul>
                <li>Multiple models as required</li>
                <li>Define model structure to describe your business and problem domains</li>
                <li>Define <DocumentLink itemKey={context.codeValues.typeModelCode}>Types</DocumentLink> for model items</li>
                <li>Define <DocumentLink itemKey={context.codeValues.ratingModelCode}>Measures</DocumentLink> and rating periods, and link to items</li>
              </ul>
            </div>
          </div>
          <div className="col">
            <h2 className="text-nowrap mb-0">
              <i className="fal fa-clock mr-2" />
              Calculations
            </h2>
            <div className="body">
              <ul>
                <li>Average, Minimum and Maximum</li>
                <li>Totals, Counts, Percentage</li>
                <li>Matrix - risk, any 2D lookup</li>
                <li>Weighted Shortest Job First</li>
                <li>Rolled up aggregates</li>
              </ul>
            </div>
          </div>
          <div className="col">
            <h2 className="text-nowrap mb-0">
              <i className="fal fa-clock mr-2" />
              Technology
            </h2>
            <div className="body">
              <ul>
                <li>Cloud Hosted on AWS</li>
                <li>API access to all data</li>
                <li>Realtime collaborative workflows using Websockets</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // function Pricing(props: any) {
  //   const className = props.className || "";

  //   return (
  //     <div className={className}>
  //       <div className="title text-center">
  //         Pricing
  //       </div>
  //       <CardDeck className="mt-4 mb-3 text-center">
  //         <Card className="mb-4 shadow-sm">
  //           <CardHeader>
  //             <h1>Free</h1>
  //           </CardHeader>
  //           <CardBody>
  //             <CardTitle className="title text-nowrap">$0 <small className="text-muted">/ mo</small></CardTitle>
  //             <ul className="list-unstyled mt-3 mb-4">
  //               <li>3 users max</li>
  //               <li>1 model</li>
  //               <li>100 items / model</li>
  //               <li>Email support</li>
  //               <li>Chat support</li>
  //             </ul>
  //             <button type="button" className="btn btn-lg btn-block btn-outline-primary">Sign up for free</button>
  //           </CardBody>
  //         </Card>
  //         <Card className="mb-4 shadow-sm">
  //           <CardHeader>
  //             <h1>Pro</h1>
  //           </CardHeader>
  //           <CardBody>
  //             <CardTitle className="title text-nowrap">$29 <small className="text-muted">/ user / mo</small></CardTitle>
  //             <ul className="list-unstyled mt-3 mb-4">
  //               <li>20 users max</li>
  //               <li>5 models max</li>
  //               <li>Unlimited items / model</li>
  //               <li>Priority email support</li>
  //               <li>Chat support</li>
  //             </ul>
  //             <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>
  //           </CardBody>
  //         </Card>
  //         <Card className="mb-4 shadow-sm">
  //           <CardHeader>
  //             <h1>Enterprise</h1>
  //           </CardHeader>
  //           <CardBody>
  //             <CardTitle className="title text-nowrap">$49 <small className="text-muted">/ user / mo</small></CardTitle>
  //             <ul className="list-unstyled mt-3 mb-4">
  //               <li>Unlimited users</li>
  //               <li>Unlimited models</li>
  //               <li>Unlimited items / model</li>
  //               <li>Priority email support</li>
  //               <li>Priority Chat support</li>
  //             </ul>
  //             <button type="button" className="btn btn-lg btn-block btn-primary">Contact us</button>
  //           </CardBody>
  //         </Card>
  //       </CardDeck>
  //     </div>
  //   )
  // }

  function About(props: any) {
    const className = props.className || "";

    return (
      <div className={className}>
        <div className="title text-center">
          About Jemstone
        </div>
        <div>
          <p>
            ...
          </p>
        </div>
      </div>
    )
  }
}
export default HomePage;
