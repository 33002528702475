import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import NetworkGraph from "../chart/NetworkGraph";
import ToolbarAction from "../control/ToolbarAction";
import { useModelContext } from "../../context/ModelContext";
import { useViewContext } from "../../context/ViewContext";
import { NetworkDataProvider } from "../chart/NetworkDataProvider";

export function NetworkGraphCard(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const rootKey = props.rootKey;

  const data = new NetworkDataProvider(model, viewState).addChildren(rootKey);

  if (data.nodes.length <= 2) {
    return <></>
  }

  return (
    <Card className="shadow">
      <CardHeader className="d-flex pt-3">
        <div className="h4 mt-0 flex-fill text-nowrap">
          Relationship Graph
        </div>
        <ToolbarAction rootKey={rootKey} docView={true} showExpandAll={true} />
      </CardHeader>
      <CardBody>
        <div className="aspect ratio-16-9">
          <NetworkGraph rootKey={rootKey} data={data} />
        </div>
      </CardBody>
    </Card>
  )
}
