import { nanoid } from 'nanoid';
import Logger from "../common/Logger";
import { ModelState } from "../context/ModelState";
import { handleEvent } from "../events/EventHandler";
import * as ModelAPI from "../services/ModelAPI";
import * as WebSocket from "../services/WebSocket";
import { CreateItemEvent, DeleteItemEvent, DemoteItemEvent, Event, PromoteItemEvent, SortItemEvent, UpdateItemEvent } from "../types/Event";
import { Item } from "../types/Item";
import { Model } from "../types/Model";

const logger = new Logger("services.ModelService");

// Counts for logging
let createId = 0;
let removeId = 0;
let updateId = 0;

// Callback function
let onModelUpdated:any;
let model:Model;

function newEventId() {
  return nanoid(8);
}

export function setModelContext(state:ModelState, onModelUpdatedCallback:any) {
  model = state.model;
  onModelUpdated = onModelUpdatedCallback;
}

/**
 * Retrieve a list of all Items in the model from ModelAPI
 */
export function loadModel() : Promise<any> {
  return ModelAPI.getItems().then(items => setItems(items, true));
}

export function setItems(items:Item[], isReload:boolean) {
  const start = Date.now();

  // Update the model
  if (isReload) {
    model.clear();
  }
  model.setItems(items, isReload);

  logger.info("setItems: Set %d items into model in %d ms", items.length, Date.now()-start);

  // Recalculate the ratings
  if (isReload) {
    calculateRatings(model);
  }

  // Notify ModelContext of the change
  if (onModelUpdated !== undefined) {
    onModelUpdated(items);
  }
}

/**
 * Retrieve events we haven't seen from ModelAPI, then pass to ModelEventHandler for processing
 * @param model
 * @param updatedTime 
 */
export function processEvents(events:Event[]): Event[] {
  logger.trace("processEvents called:", events);

  // Wait for list of actions
  if (events !== undefined && events.length > 0) {
    logger.debug("processEvents started: Received %d events to process", events.length);

    events.forEach(event => {
      handleEvent(event, model);
    });

    logger.debug("processEvents finished: Processed %d events:", events.length, events);
  }

  return (events !== undefined) ? events : [];
}

/**
 * Create list of items specified
 * @param model
 * @param parentKey The parent item for the new items
 * @param newItems 
 */
export function createItems<T extends Item>(model:Model, parentKey:string, newItems:T[]=[]): CreateItemEvent<T> {
  const start = Date.now();
  const id = ++createId;

  // Create a new item as a child of the specified parent
  if (newItems.length === 0) {
    newItems.push(model.newItem(parentKey));
  }

  // Create action
  const event:CreateItemEvent<T> = {
    id: newEventId(),
    type: "CreateItemEvent",
    time: Date.now(),
    parentKey: parentKey,
    items: newItems
  }

  logger.debug("createItems id=%d started:", id, event);

  // Dispatch action to update model, then post to server
  handleEvent<T>(event, model);
  postEvent(event);

  const duration = Date.now() - start;
  logger.debug("createItems id=%d finished in %d ms", id, duration);

  return event;
}

/**
 * Remove items with the specified keys from the model and post to server
 * @param model 
 * @param keys
 */
export function removeItems(model:Model, keys:string[]): DeleteItemEvent {
  const start = Date.now();
  const id = ++removeId;

  const event:DeleteItemEvent = {
    id: newEventId(),
    type: "DeleteItemEvent",
    time: Date.now(),
    keys: keys,
  }

  logger.debug("removeItems id=%d started: Removing %d items:", id, keys.length, event);

  // Dispatch action to update model, then post to server
  handleEvent(event, model);
  postEvent(event);

  const duration = Date.now() - start;
  logger.debug("removeItems id=%d finished in %d ms", id, duration);

  return event;
}

/**
 * Update specified attribute of specified item(s) in the model and post to server
 * @param model 
 * @param keys 
 * @param property 
 * @param value 
 */
export function updateItems<T extends Item>(
  model:Model, keys:string[], property:keyof T, value:any): UpdateItemEvent<T> 
{
  const start = Date.now();
  const id = ++updateId;

  // Create event
  const event:UpdateItemEvent<T> = {
    id: newEventId(),
    type: "UpdateItemEvent",
    time: Date.now(),
    keys: keys,
    property: property,
    value: value
  }

  logger.debug("updateItems id=%d started:", id, event);

  // Dispatch action to update model, then dispatch to server
  handleEvent<T>(event, model);
  postEvent(event);

  const duration = Date.now() - start;
  logger.debug("updateItems id=%d finished in %d ms", id, duration);

  return event;
}

/**
 * Sort item with the specified key (either UP,DOWN,ALPHA) and post to server
 * @param model 
 * @param keys
 */
export function sortItem(model:Model, key:string, direction: "UP" | "DOWN" | "ALPHA"): SortItemEvent {
  const start = Date.now();

  const event:SortItemEvent = {
    id: newEventId(),
    type: "SortItemEvent",
    time: Date.now(),
    key: key,
    direction: direction,
  }

  logger.debug("sortItem started: Removing key=%s, direction=%s:", key, direction, event);

  // Dispatch action to update model, then post to server
  handleEvent(event, model);
  postEvent(event);

  const duration = Date.now() - start;
  logger.debug("sortItem finished in %d ms", duration);

  return event;
}

export function promoteItems(model:Model, keys:string[]): PromoteItemEvent {
  const start = Date.now();

  const event:PromoteItemEvent = {
    id: newEventId(),
    type: "PromoteItemEvent",
    time: Date.now(),
    keys: keys,
  }

  logger.debug("promoteItems started: Promoting %d items:", keys.length, event);

  // Dispatch action to update model, then post to server
  handleEvent(event, model);
  postEvent(event);

  const duration = Date.now() - start;
  logger.debug("promoteItems finished in %d ms", duration);

  return event;
}

export function demoteItems(model:Model, keys:string[]): DemoteItemEvent {
  const start = Date.now();

  const event:DemoteItemEvent = {
    id: newEventId(),
    type: "DemoteItemEvent",
    time: Date.now(),
    keys: keys,
  }

  logger.debug("demoteItems started: Demoting %d items:", keys.length, event);

  // Dispatch action to update model, then post to server using ModelAPI
  handleEvent(event, model);
  postEvent(event);

  const duration = Date.now() - start;
  logger.debug("demoteItems finished in %d ms", duration);

  return event;
}

export function calculateRatings(model:Model, key:string = "") {
  const start = Date.now();
  logger.debug("calculateRatings: started, key='%s'", key);

  const ratingModel = model.getRatingModel();
  ratingModel.zeroCounters();
  // ratingModel.calculateMaxScore(key);

  const count = ratingModel.calculateDown(key);

  const duration = Date.now() - start;

  logger.debug("calculateRatings: finished in %d ms, %d items, key='%s', counters:", 
                duration, count, key, ratingModel.counters, ratingModel.itemMaxScoreMap);
}

/**
 * Here we implement a simple event queuing mechanism to batch events to be sent to the server 
 * via an API call (see postEvents function). Rationale is to minimize the number of API calls.
 * 1. A user completes an action which gives rise to the creation of an event
 * 2. postEvent is called which adds the event to the postEventQueue array, and sets a timeout
 * 3. If postEvent is called before the timeout expires, the timeout is cancelled and reset
 * 4. When the timeout expires, onTimeout invokes the postEvents function with all queued events
 */
let postEventId = 0;
let postEventQueue:Event[] = [];
let timeoutId:any = null;
let timeoutPeriod = 1000;

export function postEvent(event:Event) {
  postEventId++;
  postEventQueue.push(event);

  if (timeoutId !== null) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }
  timeoutId = setTimeout(onWakeup, timeoutPeriod);
}

function onWakeup() {
  timeoutId = null;
  if (postEventQueue.length > 0) {
    const events = postEventQueue;

    const start = Date.now();
    logger.info("onWakeup id=%d started, %d events in queue", postEventId, events.length);

    if (process.env.REACT_APP_SERVER_ENV === "AWS") {
      if (WebSocket.sendEvents(events) === false) {
        logger.error("onWakeup: Could not send events, will try again in %d ms", timeoutPeriod);
        timeoutId = setTimeout(onWakeup, timeoutPeriod);
        return;
      }
    } else {
      ModelAPI.postEvents(events);
    }

    // Success => clear queue of events
    postEventQueue = [];

    const duration = Date.now() - start;
    logger.info("onWakeup id=%d finished in %d ms, %d events in queue", postEventId, duration, events.length);
  }
}
