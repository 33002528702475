import React, { Fragment, useState } from "react";
import { Table } from "reactstrap";
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import { useViewContext } from "../../context/ViewContext";
import * as ModelService from "../../services/ModelService";
import { Item } from "../../types/Item";
import { RatingMeasure, RatingMeasureProps, RatingScaleItem } from "../../types/RatingMeasure";
import { RatingMeasureUtils } from "../../types/RatingMeasureHelper";
import { RatingValue, RatingValueProps } from "../../types/RatingValue";
import { DocumentLink } from "../../views/ViewLinks";
import ContextMenu from "../control/ContextMenu";
import ContextMenuBase from "../control/ContextMenuBase";
import Editable from "../control/Editable";
import ItemDropdown from "../control/ItemDropdown";
import RatingValueSlider from "./RatingValueSlider";

const logger = new Logger("rating.RatingAssessment");

function RatingAssessment(props: any) {
  const model = useModelContext().model;
  const viewState = useViewContext();

  const item:Item = props.item;
  const items:Item[] = props.items || [item];

  const [, onEvent] = useState<any>();

  // How to display rating values: 0=Dropdown, 1=Values, 2=Slider
  const showDropdown = (viewState.ratingDisplay === 0);
  // const showValues = (viewState.ratingDisplay === 1);
  const showSlider = (viewState.ratingDisplay === 2);

  const showItemDetails = (item === undefined);

  const periods = model.getRatingPeriods();

  logger.debug("Rendering: items:", item, items);

  if (items.length === 0) {
    return <></>
  }

  return (
    <Table className="table-rating-value text-muted m-0">
      <thead className="thead-light">
        <tr>
          { showItemDetails && 
            <th className="name">Name</th>
          }
          <th className="measure">Measure</th>
          { periods.map(period =>
            <th className="text-center" key={period.key}>{period.name}</th>
          )}
          <th className="rowmenu" style={{ width: "3rem" }}>
            <DisplayOptions />
          </th>
        </tr>
      </thead>
      <tbody>
        { items.map(child =>
          <ChildItemRow item={child} key={child.key} />
        )}
      </tbody>
    </Table>
  )

  function ChildItemRow(props: any) {
    const item = props.item;
    const [isOpen, setOpen] = useState(viewState.isOpenItem(item.key) || !showItemDetails);

    const measure = model.getMeasure(item);
    const hasChildItems = model.hasRateableChildren(item.key);
    const hasChildMeasures = measure && model.hasChildren(measure.key);

    return (
      <>
        <tr>
          { showItemDetails && 
            <td className="name">
              <DocumentLink item={item} />
            </td>
          }
          <td className="measure">
            <DocumentLink item={measure} />
          </td>
          <RatingValueCells item={item} measure={measure} />
          <td className="rowmenu">
            { !hasChildItems && hasChildMeasures &&
              <OpenItemButton />
            }
            <ContextMenu item={item} onEvent={onEvent} />
          </td>
        </tr>
        { isOpen &&
          <ChildMeasureRow item={item} measure={measure} level={1} />
        }
      </>
    )

    function OpenItemButton(props: any) {
      const classImage = (isOpen ? "far fa-angle-double-up" : "far fa-angle-double-down");
      return (
        <button className="btn-image" onClick={onClick}>
          <i className={classImage} />
        </button>
      );
  
      function onClick() {
        setOpen(viewState.toggleOpenItem(item.key).openItem);
      }
    }
  }

  function ChildMeasureRow(props: any) {
    const { item, measure, level } = props;
    const childMeasures = model.children<RatingMeasure>(measure?.key);

    const styleIndent = { paddingLeft: (0.25+level) + "rem" }

    return (
      <>
        { childMeasures.map(childMeasure =>
          <Fragment key={childMeasure.key}>
            <tr>
              { showItemDetails && 
                <td className="name"></td>
              }
              <td className="measure" style={styleIndent}>
                <DocumentLink item={childMeasure} />
              </td>
              <RatingValueCells item={item} measure={childMeasure} />
              <td></td>
            </tr>
            <ChildMeasureRow item={item} measure={childMeasure} level={level+1} />
          </Fragment>
        )}
      </>
    )
  }

  function RatingValueCells(props: any) {
    const { item, measure } = props;
    const hasScale = RatingMeasureUtils.hasScaleItems(measure);

    // Can only display slider if measure has a scale
    if (showSlider && hasScale) {
      return (
        <td className="slider align-middle pr-2" colSpan={periods.length}>
          <RatingValueSlider item={item} measure={measure} onEvent={onEvent} />
        </td>
      )
    }

    // Can only display dropdown if measure has a scale
    if (showDropdown && hasScale) {
      return (
        <>
          { periods.map(period =>
            <td className="value text-center matrix-pill" key={period.key}>
              <PickRatingValue item={item} measure={measure} period={period} onEvent={onEvent} />
            </td>
          )}
        </>
      )
    }

    // Default is to display the values
    return (
      <>
        { periods.map(period =>
          <td className="value text-center" key={period.key}>
            <EditRatingValue item={item} measure={measure} period={period} />
          </td>
        )}
      </>
    )
  }

  function DisplayOptions(props:any) {
    const display = viewState.ratingDisplay;
    return (
      <ContextMenuBase caret="far fa-ellipsis-v" logger="rating.DisplayOptions">
        <button className="btn-image item" onClick={() => setDisplay(0)} disabled={display === 0}>
          <i className="fas fa-caret-down mr-1"></i>
          Dropdown
        </button>
        <button className="btn-image item" onClick={() => setDisplay(1)} disabled={display === 1}>
          <i className="far fa-sort-numeric-down mr-1"></i>
          Values
        </button>
        <button className="btn-image item" onClick={() => setDisplay(2)} disabled={display === 2}>
          <i className="far fa-sliders-h mr-1"></i>
          Slider
        </button>
      </ContextMenuBase>
    )

    function setDisplay(display:number) {
      viewState.ratingDisplay = display;
      onEvent({name:"setRatingDisplay", value:viewState.ratingDisplay});
    }
  }

  function EditRatingValue(props:any) {
    const ratingModel = model.getRatingModel();

    const { item, measure, period } = props;
    if (!item || !measure || !period) {
      return <></>;
    }

    const ratingValue = ratingModel.getValue(item.key, measure.key, period.key, true);
    if (ratingValue === undefined) {
      return <></>;
    }

    return (
      <Editable item={ratingValue} property={RatingMeasureProps.value} onChange={onChange} numeric={true} />
    )

    function onChange(ratingValue:RatingValue, property:string, newValue:number) {
      logger.debug("EditRatingValue.onChange: property=%s, newValue='%s'",
                    property, newValue, ratingValue);

      if (ratingValue && ratingValue.value !== newValue) {
        if (!model.has(ratingValue.key)) {
          ratingValue.value = newValue;
          ModelService.createItems(model, ratingValue.parentKey, [ratingValue]);
        } else {
          ModelService.updateItems<RatingValue>(model, [ratingValue.key], RatingValueProps.value, newValue);
        }
      }    
    }
  }

  function PickRatingValue(props:any) {
    const { item, measure, period } = props;

    if (!item || !measure || !period) {
      return <></>;
    }

    const ratingModel = model.getRatingModel();
    const ratingValue = ratingModel.getValue(item.key, measure.key, period.key, true);
    if (ratingValue === undefined) {
      return <></>;
    }

    const scale = measure.ratingScale.scale;
    const selected = RatingMeasureUtils.findScaleItem(scale, ratingValue.value);

    const hasChildItems = model.hasChildren(item.key);
    const hasChildMeasures = model.hasChildren(measure.key);

    return (
      <ItemDropdown
        options={scale}
        selected={selected}
        format={formatScaleItem}
        readonly={hasChildItems || hasChildMeasures}
        onChange={(selectedItem:RatingScaleItem) => onChange(selectedItem.value)}
        className="text-center text-wrap" />
    )

    function onChange(newValue:number) {
      logger.debug("PickRatingValue.onChange: newValue='%s'", newValue, ratingValue);

      if (ratingValue && ratingValue.value !== newValue) {
        if (!model.has(ratingValue.key)) {
          ratingValue.value = newValue;
          ModelService.createItems(model, ratingValue.parentKey, [ratingValue]);
        } else {
          ModelService.updateItems<RatingValue>(model, [ratingValue.key], RatingValueProps.value, newValue);
        }
      }    
    }

    function formatScaleItem(scaleItem:RatingScaleItem) {
      return (scaleItem === null || scaleItem === undefined) ? "" : scaleItem.name;
    }
  }
}

export default RatingAssessment;
