import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import Logger from "../common/Logger";
import RatingChartCards from "../components/cards/RatingChartCards";
import { DashboardLinkParent, ItemTypeIcon } from "../components/control/ItemTypeIcon";
import ToolbarAction from "../components/control/ToolbarAction";
import RatingValueGadget from "../components/rating/RatingValueGadget";
import { useModelContext } from "../context/ModelContext";
import { Item } from "../types/Item";
import { DashboardLink } from "./ViewLinks";

const logger = new Logger("view.DashboardView");

function DashboardView(props: any) {
  const context = useModelContext();
  const model = context.model;

  const [date]  = useState(Date.now());
  const [event] = useState<any>();
  const { key } = (useParams() as any);
  const rootKey = key || "";
  
  const ratingPeriod = model.getRatingPeriodForDate(date);

  const codeValues = [
    context.codeValues.businessModelCode,
    context.codeValues.businessStrategyCode,
    context.codeValues.capabilityModelCode,
    context.codeValues.riskModelCode,
    context.codeValues.portfolioModelCode,
    context.codeValues.opModelCode
  ]

  let rootItem, keyItems:Item[];
  if (model.has(rootKey)) {
    rootItem = model.getItem(rootKey);
    keyItems = model.childrenSorted(rootKey);
  } else {
    keyItems = [];
    for (const code of codeValues) {
      const keyItem = model.getItemByCode(code);
      if (keyItem) {
        keyItems.push(keyItem);
      }
    }
  }

  logger.debug("Rendering: key='%s', keyItems:", rootKey, keyItems, props, event);

  if (keyItems === undefined || keyItems.length === 0 || keyItems[0] === undefined) {
    return <></>
  }

  return (
    <Card className="jemstone-view dashboard shadow">
      <CardHeader>
        <div className="text-nowrap d-flex align-middle">
          <div className="h1 flex-fill text-uppercase">
            { rootItem
              ? <>
                  <ItemTypeIcon item={rootItem} />
                  {' ' + rootItem.name + ' Dashboard'}
                  <DashboardLinkParent item={rootItem} />
                </>
              : <>
                  <i className="fal fa-analytics btn-image mr-2" />
                  <span>Model Dashboard</span>
                </>
            }
          </div>
          <div className="pt-1 mr-3">{rootKey}</div>
          <ToolbarAction rootKey={rootKey} docView={true} showRefresh={true} />
        </div>
      </CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-12 mb-2">
            <DashboardGaugesCard />
          </div>
        </div>
        <div className="row">
          { keyItems.map(keyItem =>
            <RatingChartCards item={keyItem} cards={keyItems.length} key={keyItem.key} />
          )}
        </div>
      </CardBody>
    </Card>
  )

  /** Create a gauge for each key item */
  function DashboardGaugesCard(props:any) {
    return (
      <Card className="gauge-card shadow">
        <CardBody>
          <div className="row row-cols-4 row-cols-md-6 row-cols-lg-8 mt-2 justify-content-center">
            { keyItems.filter(keyItem => hasRatingValue(keyItem)).map(keyItem =>
              <div className="col mb-2" key={keyItem.key}>
                <RatingValueGadget item={keyItem} showGauge={true} showCountBar={true} />
                <DashboardLink item={keyItem} className="text-wrap" />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    )
  }

  function hasRatingValue(item:Item) : boolean {
    for (const measure of model.getMeasuresForType(item)) {
      if (model.getRatingModel().getValue(item.key, measure.key, ratingPeriod.key)) {
        return true;
      }
    }
    return false;
  }
}

export default DashboardView;
