import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import Logger from "../common/Logger";
import { useModelContext } from "../context/ModelContext";
import { useViewContext } from "../context/ViewContext";
import { DocumentLink } from "../views/ViewLinks";

const logger = new Logger("layouts.JemstoneSidebar");

function JemstoneSidebar(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const isExpanded = props.expanded;
  const isResponsive = props.responsive;

  const className = (isExpanded ? "jemstone-sidebar-expanded" : "jemstone-sidebar-collapsed");
  const classLink = "jemstone-sidebar-link";
  const classTree = "jemstone-sidebar-tree";

  logger.debug("Rendering: isExpanded=%s, isResponsive=%s", isExpanded, isResponsive);

  return (
    <Card className={"jemstone-view " + className}>
      <CardHeader className="m-0 p-0">
        <HeaderLinks />
      </CardHeader>
      <CardBody className="m-0 p-0">
        <TreeView expanded={isExpanded} />
      </CardBody>
    </Card>
  )

  function HeaderLinks(props:any) {
    const style = { marginBottom: "0.8rem" } 
    const links = [
      {
        pathname: "/dashboard",
        icon: "fal fa-analytics",
        name: "Dashboard"
      },
      {
        pathname: "/canvas/BM-0001",
        icon: "far fa-users",
        name: "Business Model Canvas"
      },
      {
        pathname: "/canvas/BC-0002",
        icon: "far fa-cubes",
        name: "Capability Model Canvas"
      },
      {
        pathname: "/table",
        icon: "fal fa-table",
        name: "Table View"
      },
      {
        pathname: "/network",
        icon: "far fa-chart-network",
        name: "Relationship Graph"
      }
    ]

    return (
      <>
        { links.map(link => 
          <div className="treeitem" style={style} title={link.name} key={link.pathname}>
            <Link to={{ pathname: link.pathname }}>
              <i className={link.icon + " btn-image mr-1"} />
              { isExpanded &&
                <span className={classLink}>{link.name}</span>
              }
            </Link>
          </div>
        )}
      </>
    )
  }

  function TreeView(props:any) {
    const isExpanded = props.expanded;

    return (
      <div className="pb-4">
        { isExpanded && 
          <h3 className={classLink} style={{marginTop:"0.8rem", marginLeft:"6px"}}>Contents</h3>
        }
        { model.childrenSorted("").map(item =>
          <TreeItem key={item.key} itemKey={item.key} />
        )}
      </div>
    );

    function TreeItem(props:any) {
      const item = model.getItem(props.itemKey);
      const level = props.level || 0;
      const iconWidth = 1.5;
      const styleIndent = (level === 0) 
                        ? { marginTop: "0.8rem" } 
                        : { marginTop: "0.25rem", paddingLeft: iconWidth*(level-1) + "rem" }
      const styleIconWidth = { width: iconWidth + "rem" }
                                      
      if (item === undefined) {
        return <></>;
      }
    
      const hasViewableChildren = viewState.hasViewableChildren(model, item.key);
      const isOpenTree = viewState.isOpenTree(item.key);
      const itemType = model.getItemType(item) || item;

      const classItem = viewState.isActive(item.key) ? "active" : 
                        viewState.isSelected(item.key) ? "selected" :"treeitem";
      const expandIcon = !hasViewableChildren ? "far fa-angle-right text-muted" :
                          isOpenTree ? "far fa-angle-down" : "far fa-angle-right";

      return (
        <>
          <div className={classItem} style={styleIndent} title={item.name}>
            { isExpanded &&
              <div className="text-nowrap">
                <button className="btn-image mr-1" onClick={toggleOpenTree}>
                  { level > 0
                    ? <i className={expandIcon} style={styleIconWidth}/>
                    : <i className={itemType.typeIcon} style={styleIconWidth} />
                  }
                </button>
                <DocumentLink item={item} className={classLink} />
              </div>
            }
            { !isExpanded &&
              <Link to={{ pathname: "/document/" + item.key }} className={"btn-image " + classLink}>
                <i className={itemType.typeIcon} style={styleIconWidth} />
              </Link>
            }
          </div>
          <div className={classTree}>
            { isExpanded && isOpenTree && hasViewableChildren &&
              model.childrenSorted(item.key).map(child =>
                <TreeItem key={child.key} itemKey={child.key} level={level+1} />
              )
            }
          </div>
        </>
      )
  
      function toggleOpenTree() {
        const itemState = viewState.toggleOpenTree(item.key);
        if (itemState.openTree) {
          viewState.openTo(model, item.key);
        }
        viewState.onEvent({name:"toggleOpenTree", key:item.key, value:itemState.openTree});
      }
    }
  }
}

export default JemstoneSidebar;