import React, { useState } from "react";
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import { useViewContext } from "../../context/ViewContext";
import * as ModelService from "../../services/ModelService";

const logger = new Logger("control.ToolbarAction");

function ToolbarAction(props:any) {
  const state = useModelContext();
  const viewState = useViewContext();
  const rootKey = props.rootKey || "";
  const className = props.className || "d-none d-sm-inline pt-1";
  const docView = props.docView;
  const showExpandAll = props.showExpandAll;
  const showRefresh = props.showRefresh;
  const [loading, setLoading] = useState(false);

  const hasSelection = viewState.hasSelection();
  const hasClipboard = viewState.hasClipboardItems();

  logger.debug("Rendering: hasSelection=%s, hasClipboard=%s", hasSelection, hasClipboard);

  const classButton = "btn-image text-center ml-1";

  return (
    <div className={className}>
    { showExpandAll &&
      <>
      <button className={classButton} onClick={onCollapse} title={"Collapse all to L" + (viewState.openLevel-1)}>
        <i className="far fa-angle-double-up"></i>
      </button>
      <button className={classButton} onClick={onExpand} title={"Expand all to L" + (viewState.openLevel+1)}>
        <i className="far fa-angle-double-down"></i>
      </button>
      </>
    }
    { !docView &&
      <>
      <button className={classButton} onClick={onCreate} title={"Create New Item"}>
        <i className="far fa-plus"></i>
      </button>
      <button className={classButton} onClick={onRemove} disabled={!hasSelection}>
        <i className="far fa-trash-alt"></i>
      </button>
      <button className={classButton} onClick={onIndent} disabled={!hasSelection}>
        <i className="far fa-indent"></i>
      </button>
      <button className={classButton} onClick={onOutdent} disabled={!hasSelection}>
        <i className="far fa-outdent"></i>
      </button>
      <button className={classButton} onClick={onCut} disabled={!hasSelection}>
        <i className="far fa-cut"></i>
      </button>
      <button className={classButton} onClick={onCopy} disabled={!hasSelection}>
        <i className="far fa-copy"></i>
      </button>
      <button className={classButton} onClick={onPaste} disabled={!hasClipboard}>
        <i className="far fa-paste"></i>
      </button>
      </>
    }
      {/* <button className={classButton} onClick={toggleShowCountBar}
              title={viewState.showCountBar ? "Show aggregate score of all child items" : "Show number of child items with each score"}>
        <i className={viewState.showCountBar ? "far fa-tally" : "far fa-percentage"}></i>
      </button> */}
    { showRefresh &&
      <button className={classButton} onClick={onRefresh} title="Reload model from server">
        <i className={"far fa-sync " + (loading ? "fa-spin" : "")}></i>
      </button>
    }
    </div>
  );

  function onCollapse() {
    if (viewState.openLevel > 1) {
      viewState.setOpenLevel(state.model, rootKey, viewState.openLevel-1);
      viewState.onEvent({name:"onCollapse", value:viewState.openLevel});
    }
  }

  function onExpand() {
    viewState.setOpenLevel(state.model, rootKey, viewState.openLevel+1);
    viewState.onEvent({name:"onExpand", value:viewState.openLevel});
  }

  function onCreate() {
    // Create a child of the root-level, or of the first selected item
    const selectedKeys = viewState.getSelectedKeys();
    const parentKey = (selectedKeys.length > 0) ? selectedKeys[0] : rootKey;

    const event = ModelService.createItems(state.model, parentKey);
    viewState.onEvent(event);
    viewState.clearSelected();
  }

  function onRemove() {
    const selectedKeys = viewState.getSelectedKeys();
    logger.debug("onRemove: selected:", selectedKeys);

    const event = ModelService.removeItems(state.model, selectedKeys);
    viewState.onEvent(event);
    viewState.clearSelected();
  }

  function onCut() {
    const selectedKeys = viewState.getSelectedKeys();

    // Ensure we cut to clipboard a deep copy of all child items
    const keys = Array.from(selectedKeys);
    for (const key of selectedKeys) {
      state.model.childrenKeysDeep(key, keys);
    }

    logger.debug("onCut: selectedKeys and keys:", selectedKeys, keys);

    // Put all cut items on the clipboard
    viewState.setClipboard(state.model.getItemArray(keys));
    viewState.clearSelected();

    const event = ModelService.removeItems(state.model, selectedKeys);
    viewState.onEvent(event);
  }

  function onCopy() {
    const selectedKeys = viewState.getSelectedKeys();
    logger.debug("onCopy: selected:", selectedKeys);

    viewState.setClipboard(state.model.getItemArray(selectedKeys));    
    viewState.onEvent({name:"onCopy", value:selectedKeys});
    viewState.clearSelected();
  }

  function onPaste() {
    // Create a child of the root-level, or of the first selected item
    const selectedKeys = viewState.getSelectedKeys();
    const parentKey = (selectedKeys.length > 0) ? selectedKeys[0] : rootKey;

    const items = viewState.getClipboard();
    logger.debug("onPaste: parentKey=%s, items:", parentKey, items);

    const event = ModelService.createItems(state.model, parentKey, items);
    viewState.onEvent(event);
    viewState.clearSelected();
  }

  function onIndent() {
    const selectedKeys:string[] = viewState.getSelectedKeys();
    logger.debug("onIndent: selected:", selectedKeys);
    
    if (selectedKeys.length !== 0) {
      const event = ModelService.demoteItems(state.model, selectedKeys);
      viewState.onEvent(event);
      viewState.clearSelected();
    }
  }

  function onOutdent() {
    const selectedKeys = viewState.getSelectedKeys();
    logger.debug("onOutdent: selected:", selectedKeys);
    
    if (selectedKeys.length !== 0) {
      const event = ModelService.promoteItems(state.model, selectedKeys);
      viewState.onEvent(event);
      viewState.clearSelected();
    }    
  }

  function onRefresh() {
    logger.debug("onRefresh started");
    setLoading(true);
    ModelService.loadModel()
    .then(() => setLoading(false));
  }

  // function toggleShowCountBar() {
  //   viewState.showCountBar = !viewState.showCountBar;
  //   viewState.onEvent({ name: "ToolbarAction.toggleShowCountBar", value: viewState.showCountBar });
  // }
}

export default ToolbarAction;
