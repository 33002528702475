import Logger from "../common/Logger";
import * as ModelService from "../services/ModelService";
import { CreateItemEvent, DeleteItemEvent, DemoteItemEvent, EventHandler, PromoteItemEvent, SortItemEvent, UpdateItemEvent } from "../types/Event";
import { Item, ItemProps, ItemStatus } from "../types/Item";
import { Model, setProperty } from "../types/Model";

const logger = new Logger("events.ModelEventHandler");

export class ModelEventHandler<T extends Item> implements EventHandler {
  protected model: Model;

  constructor(model:Model) {
    this.model = model;
  }

  public handle(event:any): void {
    switch (event.type) {
      case "CreateItemEvent":
        return this.handleCreate(event);

      case "UpdateItemEvent":
        return this.handleUpdate(event);

      case "DeleteItemEvent":
        return this.handleDelete(event);

      case "SortItemEvent":
        return this.handleSortItem(event);

      case "PromoteItemEvent":
        return this.handlePromoteItem(event);

      case "DemoteItemEvent":
        return this.handleDemoteItem(event);
        
      default: throw new Error("Unsupported Event: " + event.type);
    }
  }

  protected handleCreate(event:CreateItemEvent<T>) {
    // Clone and set the status
    const newItems = event.items.map(item => this.clone(item, ItemStatus.NEW));
    const newKeys = newItems.map(item => item.key);

    // Update the parentKey unless it points to another item being created
    newItems.forEach(item => {
      if (newKeys.indexOf(item.parentKey) === -1) {
        item.parentKey = event.parentKey;
      }
    });

    // Update the model
    ModelService.setItems(newItems, false);

    logger.info("handleCreate: Created %d items:", newItems.length, event, newItems);
  }

  protected handleUpdate(event:UpdateItemEvent<T>) {
    logger.trace("handleUpdate starting:", event);
    
    const newItems:Item[] = [];

    // Loop through each key
    for (const key of event.keys) {
      // Clone old item - first assignment to item:any is enable rest of code to be type checked
      const item:any = this.model.getItem(key);
      const oldItem:T = item;
      const newItem:T = this.clone(oldItem, ItemStatus.MODIFIED);

      // Update property as specified in Event
      setProperty(newItem, event.property, event.value);
      newItems.push(newItem);
    }

    logger.debug("handleUpdate finished: Updated %d items:", newItems.length, event, newItems);

    // Update model
    ModelService.setItems(newItems, false);
  }

  protected handleDelete(event:DeleteItemEvent) {
    logger.debug("handleDelete starting:", event);

    // Ensurw we delete all children of specified keys
    const keys = Array.from(event.keys);
    event.keys.forEach(key => {
      this.model.childrenKeysDeep(key, keys);
    });

    // Build array of items to be deleted
    const deleteItems:Item[] = [];
    keys.forEach(key => {
      const item = this.model.getItem(key);
      if (item !== undefined) {
        deleteItems.push(this.clone(item, ItemStatus.DELETED));
      }
    });

    // Update model
    ModelService.setItems(deleteItems, false);

    logger.info("handleDelete finished: Deleted %d items:", deleteItems.length, event, deleteItems);
  }

  protected handleSortItem(event:SortItemEvent) {
    logger.debug("handleSortItem starting:", event);

    const item = this.model.getItem(event.key);
    if (!item) {
      logger.warn("handleSortItem: itemKey=%s does not exist", event.key);
      return;
    }
    const siblings = this.model.childrenSorted(item.parentKey);
    const index = siblings.findIndex(sibling => (sibling.key === item.key));
  
    // Recalc sortOrder for all siblings, while cloning the siblings
    let sortOrder = 100;
    const increment = (event.direction === "ALPHA" ? 0 : 1);
    const newItems:Item[] = [];
    for (const sibling of siblings) {
      const newItem = this.clone(sibling, ItemStatus.MODIFIED);
      newItem.sortOrder = (sortOrder += increment);
      newItems.push(newItem);
    }

    // Swap sortOrder for specified item according to 'direction'
    const swapIndex = index + (event.direction === "UP"  ? -1 :
                              (event.direction === "DOWN" ? 1 : 0));
    if (swapIndex >= 0 && swapIndex < newItems.length) {
      const swapOrder = newItems[swapIndex].sortOrder;
      newItems[swapIndex].sortOrder = newItems[index].sortOrder;
      newItems[index].sortOrder = swapOrder;

      // Update model
      ModelService.setItems(newItems, false);
    }

    logger.info("handleSortItem finished: Updated %d items:", newItems.length, event, newItems);
  }

  protected handlePromoteItem(event:PromoteItemEvent) {
    logger.debug("handlePromoteItem starting:", event);

    const parentKey = this.model.getItem(event.keys[0])?.parentKey;
    const newParentKey = this.model.getItem(parentKey).parentKey;
    
    if (newParentKey) {
      // Only include siblings of the first item
      const newItems:Item[] = [];
      for (const key of event.keys) {
        const item = this.model.getItem(key);
        if (item.parentKey !== undefined && item.parentKey !== "" && item.parentKey === parentKey) {
          const newItem = this.clone(item, ItemStatus.MODIFIED);
          newItem.parentKey = newParentKey;
          newItems.push(newItem);
        }
      }

      // Update model
      ModelService.setItems(newItems, false);

      logger.info("handlePromoteItem finished: Updated %d items:", newItems.length, event, newItems);
    }
  }

  protected handleDemoteItem(event:DemoteItemEvent) {
    logger.debug("handleDemoteItem starting:", event);

    const itemKey = event.keys[0];
    const parentKey = this.model.getItem(itemKey)?.parentKey;

    if (parentKey) {
      const siblings = this.model.childrenSorted(parentKey);
      const index = siblings.findIndex(sibling => (sibling.key === itemKey));
      
      if (index > 0) {
        const newParentKey = siblings[index-1].key;
        const newItems:Item[] = [];

        // Only include siblings of the first item
        for (const key of event.keys) {
          const item = this.model.getItem(key);
          if (item.parentKey === parentKey) {
            const newItem = this.clone(item, ItemStatus.MODIFIED);
            newItem.parentKey = newParentKey;
            newItems.push(newItem);
          }
        }

        // Update model
        ModelService.setItems(newItems, false);

        logger.info("handleDemoteItem finished: Updated %d items:", newItems.length, event, newItems);
      }
    }
  }

  protected clone<I extends Item>(item:I, status:ItemStatus): I {
    const newItem:I = {...item,
      status: status,
      modifiedDate: Date.now(),
    }

    logger.trace("clone: item => newItem:", item, newItem);
    return newItem;
  }
}
