import Nouislider, { Formatter } from 'nouislider-react';
import "nouislider/distribute/nouislider.css";
import React, { useRef } from 'react';
import "../../assets/css/jemstone.noui-rating.css";
import Logger from '../../common/Logger';
import { useModelContext } from '../../context/ModelContext';
import * as ModelService from "../../services/ModelService";
import { RatingMeasureUtils } from '../../types/RatingMeasureHelper';
import { RatingValue, RatingValueProps } from '../../types/RatingValue';
import { PeriodDataProvider, ScaleDataProvider } from '../chart/DataProvider';


const logger = new Logger("rating.RatingValueSlider");

function RatingValueSlider(props:any) {
  const model = useModelContext().model;
  const item = props.item;
  const className = props.className || "";
  const containerRef = useRef(null);

  // Set rating period names on the slider handles
  React.useEffect(() => {
    updateHandles();
  });

  // RatingMeasure is the first for this item unless passed as a parameter
  const measure = props.measure || model.getMeasure(item);
  if (!item || !measure || !RatingMeasureUtils.hasScaleItems(measure)) {
    logger.trace("Cannot find RatingMeasure for item:", item);
    return <></>;
  }

  const ratingModel = model.getRatingModel();
  const ratingPeriods = model.getRatingPeriods();

  // Get required data in easy to consume form
  const periodData = new PeriodDataProvider(model, measure, item);
  const scaleData = new ScaleDataProvider(model, measure, item);
  const padding=1;
  const direction = (scaleData.maxValue < scaleData.minValue) ? "rtl" : "ltr";

  const formatter:Formatter = {
    to: formatTooltip,
    from: function (str:string):number { return 0 }
  }

  const tooltips = ratingPeriods.map(period => formatter);

  logger.debug("Rendering: itemKey=%s, measureKey=%s - %s", item.key, measure.key, measure.name);

  return (
    <div ref={containerRef} className={"justify-content-center " + className} title={measure.name}>
      <Nouislider
        start={periodData.values}
        range={{ min: (scaleData.minValue - padding), max: (scaleData.maxValue + padding) }}
        step={0.5}
        padding={padding}
        connect={false}
        tooltips={tooltips}
        direction={direction}
        onEnd={onEnd}
        onUpdate={onUpdate}
      />
    </div>
  );

  function formatTooltip(value:number) : string {
    return scaleData.getScaleItemName(value);
  }

  function updateHandles() {
    const element:any = containerRef.current;
    if (element) {
      // Set period names on slider handles
      const handles = element.querySelectorAll('.noUi-handle');
      handles.forEach((handle:any, i:number) => {
        const value = (periodData.values[i] / scaleData.maxValue) * scaleData.maxScale;
        const index = RatingMeasureUtils.findScaleItemIndex(measure.ratingScale.scale, value);

        handle.setAttribute("data-value", periodData.names[i]);
        handle.style.backgroundColor = scaleData.colors[index];
      });
    }
  }
    
  function onEnd(values:any[], handle:number, unencoded:any[], tap:boolean, positions:any[]) {
    const newValue = unencoded[handle];
    const ratingPeriod = ratingPeriods[handle];
    const ratingValue = ratingModel.getValue(item.key, measure.key, ratingPeriod.key);

    if (ratingValue && ratingValue.value !== newValue) {
      periodData.values[handle] = newValue;

      logger.debug("onEnd: handle=%d, value=%s, itemKey=%s, measureKey=%s periodKey=%s", 
                    handle, newValue, item.key, measure.key, ratingPeriod.name);

      if (!model.has(ratingValue.key)) {
        ratingValue.value = newValue;
        ModelService.createItems(model, ratingValue.parentKey, [ratingValue]);
      } else {
        ModelService.updateItems<RatingValue>(model, [ratingValue.key], RatingValueProps.value, newValue);
      }
    }
  }

  function onUpdate(values:any[], handle:number, unencoded:any[], tap:boolean, positions:any[]) {
    periodData.values[handle] = unencoded[handle];
    updateHandles();
  }
}

export default RatingValueSlider;
