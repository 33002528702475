import React from 'react';
import { Table } from 'reactstrap';
import Logger from "../../common/Logger";
import { setProperty } from "../../types/Model";
import { RatingMeasureProps, RatingScale, RatingScaleItem } from '../../types/RatingMeasure';
import ColorPicker from '../control/ColorPicker';
import ContextMenuBase from '../control/ContextMenuBase';
import Editable from '../control/Editable';

const logger = new Logger("rating.RatingScaleEditor");

function RatingScaleEditor(props:any) {
  const ratingScale:RatingScale = props.ratingScale;
  const onChange = props.onChange;

  logger.debug("Rendering:", ratingScale);

  return (
    <Table>
      <thead className="thead-light">
        <tr>
          <th>Value</th>
          <th>Name</th>
          <th>Description</th>
          <th>Color</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { ratingScale && ratingScale.scale.map((scaleItem:RatingScaleItem,index) =>
          <tr key={scaleItem.value}>
            <td><Editable item={scaleItem} property={RatingMeasureProps.value} onChange={onChangeScaleItem} numeric={true} /></td>
            <td className="text-nowrap"><Editable item={scaleItem} property={RatingMeasureProps.Name} onChange={onChangeScaleItem} /></td>
            <td className="text-nowrap pr-3"><Editable item={scaleItem} property={RatingMeasureProps.description} onChange={onChangeScaleItem} /></td>
            <td className="align-middle"><ColorPicker item={scaleItem} property={RatingMeasureProps.color} onChange={onChangeScaleItem} /></td>
            <td className="rowmenu">
              <ContextMenu item={scaleItem} index={index} />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  function onChangeScaleItem(scaleItem:RatingScaleItem, property:keyof RatingScaleItem, newValue:any) {
    try {
      logger.debug("onChangeScaleItem: property=%s, value=", property, newValue, scaleItem);

      if (scaleItem !== undefined) {
        setProperty(scaleItem, property, newValue);
        onChange(ratingScale);
      }
    } catch (e) {
      logger.error("onChangeScaleItem: Exception caught:", e);
      alert(e);
    }
  }

  function ContextMenu(props:any) {
    return (
      <ContextMenuBase logger="rating.RatingScaleEditor.ContextMenu">
        <button className="btn-image" onClick={() => {}}>
          <i className="far fa-plus"></i>
        </button>
        <button className="btn-image pl-1 pr-1" onClick={() => {}}>
          <i className="far fa-trash-alt"></i>
        </button>
      </ContextMenuBase>
    );
  }
}

export default RatingScaleEditor;
