import React from 'react';
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import { useViewContext } from '../../context/ViewContext';
import * as ModelService from '../../services/ModelService';
import { CanvasLink, DocumentLink } from '../../views/ViewLinks';
import { ItemTypeIcon } from '../control/ItemTypeIcon';

const logger = new Logger("canvas.ModelCanvas");

function ModelCanvas(props:any) {
  const model = useModelContext().model;
  const { rootKey, showKey, showDescription, docView, onEvent } = props;

  logger.debug("Rendering: showKey=%s, showDescription=%s", showKey, showDescription);

  return (docView ? <DocViewCanvas/> : <SwimlaneCanvas/>);

  function SwimlaneCanvas(props:any) {
    return (
      <div className="model-canvas">
        { model.childrenSorted(rootKey).map(item =>
          <div className="row swimlane" key={item.key}>
            <div className="col-4 col-sm-3 col-lg-2 col1 mt-1">
              <b>
                { docView
                  ? <DocumentLink item={item}/>
                  : <CanvasLink item={item}/>
                }
              </b>
            </div>
            <div className="col-8 col-sm-9 col-lg-10">
              { showDescription &&
                <div className="row">
                  <div className="col col1">
                    {item.description}
                  </div>
                </div>
              }
              <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 row-cols-xxxl-8">
                { model.childrenSorted(item.key).map(child =>
                  <div className="col col1" key={child.key}>
                    <div className="item">
                      <DocumentLink item={child} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  function DocViewCanvas(props:any) {
    return (
      <div className="model-canvas d-none d-sm-block">
        <div className="row">
          <div className="col">
            <div className="item">
              <div className="h2 d-flex">
                <div className="flex-fill">
                  <div className="text-center">{model.getItem(rootKey).name}</div>
                </div>
                <button className="btn-image" onClick={() => onCreateChild()}>
                  <i className="far fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 row-cols-xxxl-6">
          { model.childrenSorted(rootKey).map(item =>
            <div className="col" key={item.key}>
              <CanvasItem itemKey={item.key} className="item" />
            </div>
          )}
          </div>
      </div>
    )
  }

  function onCreateChild() {
    logger.debug("onCreateChild: parentKey=%s", rootKey);

    const event = ModelService.createItems(model, rootKey);
    if (onEvent) {
      onEvent(event);
    }
  }
}

export function CanvasItem(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();

  const itemKey = props.itemKey;
  const level = props.level || 0;
  const className = props.className || "";
  const showDescription = props.showDescription;

  const item = model.getItem(itemKey);
  if (item === undefined || !viewState.isVisible(item.key)) {
    return <></>;
  }

  const isOpenTree = viewState.isOpenTree(item.key);
  const hasChildren = viewState.hasViewableChildren(model, item.key);
  const hasDescription = showDescription && item.description !== "";

  return (
    <div className={className}>
      { level === 0 &&
        <div className={"h4 d-flex"}>
          <div className="flex-fill">
            <DocumentLink item={item}/>
          </div>
          <ItemTypeIcon item={item} />
        </div>
      }
      <div className="body">
        { level > 0 && 
          <div className={((isOpenTree && hasChildren) || hasDescription) ? "mt-1" : "text-muted"}>
            <DocumentLink item={item}/>
          </div>
        }
        { hasDescription &&
          <div className="text-muted">{item.description}</div>
        }
        { isOpenTree && model.hasChildren(item.key) &&
          <ul>
            { model.childrenSorted(item.key).map(child =>
              <li key={child.key}>
                <CanvasItem itemKey={child.key} showDescription={showDescription} level={level+1}/>
              </li>
            )}
          </ul>
        }
      </div>
    </div>
  )
}

export default ModelCanvas;
