import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import { Item } from "../../types/Item";
import { RatingMeasure } from "../../types/RatingMeasure";
import { DashboardLink, DocumentLink } from "../../views/ViewLinks";
import { RatingChartBar } from "../chart/RatingChartBar";
import { RatingChartBullet } from "../chart/RatingChartBullet";
import { RatingChartBump } from "../chart/RatingChartBump";
import { RatingChartGauge } from "../chart/RatingChartGauge";
import { RatingChartPie } from "../chart/RatingChartPie";
import { RatingChartRadar } from "../chart/RatingChartRadar";
import { ItemTypeIcon } from "../control/ItemTypeIcon";
import RiskMatrixCard from "./RiskMatrixCard";

const logger = new Logger("cards.RatingChartCards");

interface ChartGroup {
  measure: RatingMeasure;
  chartKey: string;
  items: Item[][];
}

/**
 * Display a chart for each distinct measure in the passed set of items
 * @param props item
 */
function RatingChartCards(props:any) {
  const model = useModelContext().model;
  const { item, docView, cards } = props;

  // Group data to be charted
  const groups = getGroups(item);

  logger.debug("Rendering: chartGroups:", groups);

  let classColumn2 = "col-12";
  if (groups.length > 1 || cards > 1) {
    classColumn2 += " col-lg-6";
  }
  if (groups.length > 2 || cards > 2) {
    classColumn2 += " col-xxl-4";
  }
  if (groups.length > 3 || cards > 3) {
    classColumn2 += " col-xxxl-3";
  }

  if (docView) return (
    <section>
      <div className="row">
        { groups.map(group => 
          <div className={classColumn2 + " mb-2"} key={group.measure.key}>
            <DashboardCard group={group} key={group.measure.key} />
          </div>
        )}
      </div>
    </section>
  )

  const classColumn = (groups.length > 1 || cards > 1) 
                    ? "col-12 col-lg-6 col-xxl-4 col-xxxl-3 col-xxxxl-2"
                    : "col-12";
  return (
    <> 
    { groups.map(group => 
      <div className={classColumn + " mb-2"} key={group.measure.key}>
        <DashboardCard group={group} />
      </div>
    )}
    </>
  )

  function DashboardCard(props:any) {
    const [level, setLevel] = useState(1);
    const [showKey, setShowKey] = useState(false);
    const group:ChartGroup = props.group;

    logger.debug("DashboardCard rendering: level=%d", level);

    return (
      <Card className="shadow">
        { !docView &&
          <CardHeader className="d-flex pt-3">
            <div className="d-inline-block align-top">
              <ItemTypeIcon item={item} />
            </div>
            <div className="flex-fill ml-2">
              <div className="d-flex">
                <div className="h2 mt-0 flex-fill text-nowrap">
                  <DashboardLink item={item} />
                </div>
                <Toolbar />
              </div>
              <Subtitle group={group} level={level} />
            </div>
          </CardHeader>
        }
        { docView &&
          <CardHeader className="d-flex pt-3">
            <div className="h4 mt-0 flex-fill text-wrap">
              <Subtitle group={group} level={level} />
            </div>
            <Toolbar />
          </CardHeader>
        }
        <CardBody>
          <Chart group={group} level={level-1} />
          { showKey &&
            <ChartKey group={group} level={level-1} />
          }
        </CardBody>
      </Card>
    )

    function Toolbar(props:any) {
      return (
        <div className="text-nowrap">
          <button className="btn-image" 
                  onClick={() => setShowKey(!showKey)} 
                  title="Show/hide key">
            <i className="far fa-list-ol"></i>
          </button>
          <button className="btn-image" 
                  disabled={level <= 1} 
                  onClick={() => setLevel(level-1)} 
                  title={"Rollup to L" + (level - 1)}>
            <i className="far fa-angle-double-up"></i>
          </button>
          <button className="btn-image" 
                  disabled={level === group.items.length} 
                  onClick={() => setLevel(level+1)} 
                  title={"Drilldown to L" + (level + 1)}>
            <i className="far fa-angle-double-down"></i>
          </button>
        </div>
      )
    }
  }

  function Subtitle(props:any) {
    const { group, level } = props;

    const sentence:any[] = [];
    const items = group.items[level-1];
    const length = items.length;

    for (let i=0;  i < length;  i++) {
      let space = (i === 0) ? " " : 
                  (length > 2) ? ", " : " and ";

      sentence.push(
        <Fragment key={i}>
          {space}<DocumentLink item={items[i]} />
        </Fragment>
      );

      if (i === 1 && length > 2) {
        sentence.push(
          <Fragment key={i*100}> and {length-2} more</Fragment>
        );
        break;
      }
    }

    return (
      <div className="subtitle">
        <DocumentLink item={group.measure}/> for
        {sentence}
      </div>
    )
  }

  function Chart(props:any) {
    const { group, level } = props;
    const items = group.items[level];

    let classRow = "row row-cols-1 row-cols-lg-2";
    if (items.length >= 3) {
      classRow += " row-cols-xl-3";
    }
    if (items.length >= 4) {
      classRow += " row-cols-xxl-4";
    }
    if (items.length >= 5) {
      classRow += " row-cols-xxxl-5";
    }

    switch (group.chartKey) {
      case "NIVO-BAR":
        return <RatingChartBar items={items} measure={group.measure} stacked={false} classRow={classRow} />

      case "NIVO-BARSTACKED":
        return <RatingChartBar items={items} measure={group.measure} stacked={true} classRow={classRow} />

      case "NPS":
      case "NIVO-BULLET":
        return <RatingChartBullet items={items} measure={group.measure} classRow={classRow} />

      case "NIVO-BUMP":
        return <RatingChartBump items={items} measure={group.measure} classRow={classRow} />
  
      case "NIVO-PIE":
        return <RatingChartPie items={items} measure={group.measure} classRow={classRow} />

      case "NIVO-RADAR":
        return <RatingChartRadar items={items} measure={group.measure} classRow={classRow} />

      case "GAUGE":
        return <RatingChartGauge items={items} measure={group.measure} classRow={classRow} />

      case "MATRIX":
        return <RiskMatrixCard items={items} measure={group.measure} classRow={classRow} />

      case "RANK-TABLE":
      default:
        return <div>Coming soon - support for {group.chartKey}</div>
    }
  }

  function ChartKey(props:any) {
    const { group, level } = props;
    const items:Item[] = group.items[level];

    return (
      <Table className="chart-key text-muted mt-2">
        <thead className="thead-light">
          <tr>
            <th style={{minWidth:"30%"}}>Name</th>
            <th style={{maxWidth:"50%"}} className="d-none d-lg-table-cell">Description</th>
          </tr>
        </thead>
        <tbody>
          { items.map(item =>
            <tr key={item.key}>
              <td>
                <div className="max-2lines"><DocumentLink item={item} /></div>
              </td>
              <td className="d-none d-lg-table-cell">
                <div className="max-2lines">{item.description}</div>
              </td>
            </tr>
            )}
        </tbody>
      </Table>
    )
  }

  function getGroups(item:Item): ChartGroup[] {
    const chartGroups = new Map<string,ChartGroup>();

    getGroupsDeep(item, chartGroups, 0);

    const groups = Array.from(chartGroups.values())
                        .sort((g1,g2) => g1.chartKey.localeCompare(g2.chartKey));

    return groups;
  }

  function getGroupsDeep(item:Item, chartGroups: Map<string,ChartGroup>, level:number) {
    for (const child of model.childrenSorted(item.key)) {
      const chartKeys = model.getItemType(item).chartKeys;
      const measures = model.getMeasuresForType(child);

      for (let i=0; i < measures.length;  i++) {
        const measure = measures[i];
        const chartKey = (chartKeys && i < chartKeys.length) ? chartKeys[i] : measure.chartType?.key;

        // Get group for measure, creating new one as required
        let group = chartGroups.get(measure.key);
        if (group === undefined) {
          group = { measure:measure, chartKey:chartKey, items:[] }
          chartGroups.set(measure.key, group);
        }

        // Create array for level if required
        for (let i=0; i <= level; i++) {
          if (group.items.length <= i || group.items[i] === undefined) {
            group.items.push([]);
          }
        }

        // Add item to level within group
        group.items[level].push(child);
      }

      // Now recurse
      getGroupsDeep(child, chartGroups, level+1);
    }
  }
}

export default RatingChartCards;