import React, { useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Logger from "../common/Logger";
import CanvasView from "../views/CanvasView";
import DashboardView from "../views/DashboardView";
import DocumentView from "../views/DocumentView";
import HomePage from "../views/HomePage";
import NetworkView from "../views/NetworkView";
import TableView from "../views/TableView";
import JemstoneHeader from "./JemstoneHeader";
import JemstoneSidebar from "./JemstoneSidebar";

const logger = new Logger("layout.JemstoneLayout");

function JemstoneLayout(props:any) {
  const sidebar = (props.sidebar === undefined ? true : props.sidebar);
  const [isExpanded, setExpanded] = useState(sidebar);
  const [isResponsive, setResponsive] = useState(true);

  logger.trace("Rendering:", props);

  return (
    <div className="jemstone-body">
      <div className="jemstone-header">
        <JemstoneHeader expanded={isExpanded} setExpanded={setExpanded}
                        responsive={isResponsive} setResponsive={setResponsive} />
      </div>
      <div className="jemstone-main">
        <div className={"jemstone-sidebar" + (isResponsive ? " responsive" : "")}>
          <JemstoneSidebar expanded={isExpanded} responsive={isResponsive} />
        </div>
        <div className="jemstone-content">
          <Switch>
            <Route path="/document/:key"  component={ withRouter(DocumentView) } />
            <Route path="/document"       component={ withRouter(DocumentView) } />
            <Route path="/table/:key"     component={ withRouter(TableView) } />
            <Route path="/table"          component={ withRouter(TableView) } />
            <Route path="/canvas/:key"    component={ withRouter(CanvasView) } />
            <Route path="/canvas"         component={ withRouter(CanvasView) } />
            <Route path="/network/:key"   component={ withRouter(NetworkView) } />
            <Route path="/network"        component={ withRouter(NetworkView) } />
            <Route path="/dashboard/:key" component={ withRouter(DashboardView) } />
            <Route path="/dashboard"      component={ withRouter(DashboardView) } />
            <Route path="/home"           component={ withRouter(HomePage) } />
            <Redirect from="*" to="/dashboard" />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default JemstoneLayout;